
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGarage } from '../contexts/selectedGarage';
import { useUser } from '../contexts/userInfo-context';
import { useEffect, useState } from 'react';


const SelectHeader = ({ options, value }: { options: string[], value?: string }) => {
    const { garageId } = useParams<{ garageId: string }>();

    const { selectedGarage, setSelectedGarage } = useGarage();

    const location = useLocation()
    const navigate = useNavigate();
    const handleSortChange = (event: any) => {
        setSelectedGarage(event.target.value);
        if (garageId) {
            if (event.target.value === "All") {
                navigate(location.pathname.replace(`/${garageId}`, ""));
                return;
            }
            navigate(location.pathname.replace(garageId, event.target.value));
        } else {
            navigate(location.pathname + "/" + event.target.value);
        }
    }

    useEffect(() => {
        if (!garageId) {
            setSelectedGarage("All");
        }
    }, [garageId, setSelectedGarage]);

    return (
        <div className="flex items-center border border-solid border-black rounded-full h-8 w-40">
            <div className='flex items-center w-full relative'>
                <select
                    value={value ? value : selectedGarage}
                    className="z-10 cursor-pointer block py-2.5 pl-4 pr-7  w-40 marker:text-lg bg-transparent appearance-none text-[var(--primary)] font-bold focus:outline-none focus:ring-0 transition duration-500 ease-in"
                    onChange={handleSortChange}
                >
                    {options.map(option => {
                        return (
                            <option
                                key={option}
                                value={option}
                                style={{
                                    fontWeight: selectedGarage === option ? 'bold' : 'normal',
                                    color: selectedGarage === option ? 'var(--primary)' : 'black'
                                }}
                            >
                                {option !== "All" ? option : "Todas"}
                            </option>
                        );
                    })}
                </select>
                <p className='rotate-90 absolute right-5 text-xl text-[var(--primary)]'>&gt;</p>
            </div>
        </div>
    )
};


const ProfileComponent = () => {
    const { user } = useUser();
    let letter = user!.firstName[0].toUpperCase();

    const [imgSrc, setImgSrc] = useState(user!.image ? user!.image : `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-30 0 100 100'%3E%3Ctext y='0.9em' font-size='90' fill='white'%3E${letter}%3C/text%3E%3C/svg%3E`);

    useEffect(() => {
        setImgSrc(user!.image ? user!.image : `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-30 0 100 100'%3E%3Ctext y='0.9em' font-size='90' fill='white'%3E${letter}%3C/text%3E%3C/svg%3E`);
    }, [user]);

    return (<div className='flex'>
        <div className='flex items-center justify-end px-5'>
            <div>
                <p className='font-bold text-end'>{user!.firstName} {user!.lastName}</p>
                <span className='font-bold text-slate-400 text-end'>{user!.type}</span>
            </div>
        </div>
        <img
            className="w-16 aspect-square border-2 border-black rounded-full bg-[var(--primary)]"
            src={imgSrc}
            alt="profileImage"
            onError={(e) => {
                setImgSrc(`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-30 0 100 100'%3E%3Ctext y='0.9em' font-size='90' fill='white'%3E${letter}%3C/text%3E%3C/svg%3E`);
            }}
        />
    </div>)
};


const Header = ({ title, options, value }: { title: string, options?: string[], value?: string }) => {

    const HeaderTop = () => {
        return (
            <div className='flex-col flex'>
                <div className='w-full flex justify-between seis:px-[4vw] px-[8vw] items-center h-[8rem]'>
                    <h1 className="flex items-center text-3xl font-bold">{title}</h1>
                    <div className="flex items-center gap-8">
                        {options && options.length > 1 && <SelectHeader options={options} value={value} />}
                        <div className='hidden sete:block'>
                            <ProfileComponent />
                        </div>
                    </div>
                </div>
                <hr className="w-full border-t-2 border-black" />
            </div>

        )
    }



    return (
        <HeaderTop />
    );
}

export default Header;

