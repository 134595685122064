import { useEffect, useState } from 'react';
import Logo from '../../content/images/icons/logo.png';
import carParking from '../../content/images/others/login-img.png';
import { Link, useNavigate } from 'react-router-dom';
import { IoLogoChrome } from "react-icons/io";
import axios from 'axios';
import { useUser } from '../../contexts/userInfo-context';
import Loading from '../../components/loading/loading';
import { useForm } from 'react-hook-form';
import { signInWithGoogle } from '../../firebase/firebase';
import toast, { Toaster } from 'react-hot-toast';


export default function Login() {
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    useEffect(() => {
        if (user) {
            navigate('/home');
        }
    }, []);


    const [loading, setLoading] = useState(false);
    // const [remember, setRemember] = useState(JSON.parse(localStorage.getItem('remember') || 'false'));
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }

    const handleLogin = handleSubmit(async (data) => {

        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/login`, {
                email: data.email,
                password: data.password
            });
            if (response.status === 200) {
                const userData = {
                    ...response.data.data,
                    accessToken: response.data.token.stsTokenManager.accessToken,
                    refreshToken: response.data.token.stsTokenManager.refreshToken,
                };
                setUser(userData);
                sessionStorage.setItem('user', JSON.stringify(userData));
                navigate('/home');
            } else {
                toast.error('Credenciais inválidas. Tente novamente.');
                console.error('Login failed');
            }
        } catch (error) {
            toast.error('Credenciais inválidas. Tente novamente.');
            console.error('Login failed', error);
        }
        setLoading(false);
    });

    const handleGoogleLogin = async () => {
        try {
            const idToken = await signInWithGoogle();
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/data`, {}, {
                    headers: {
                        'Authorization': `${idToken}`
                    }
                });
                if (response.status === 200) {
                    const userData = response.data.data;
                    setUser(userData);
                    sessionStorage.setItem('user', JSON.stringify(user));
                    // if (remember === 'true') {
                    //     localStorage.setItem('user', JSON.stringify(user));
                    // }
                    navigate('/home');
                } else if (response.status === 404) {
                    toast.error('Utilizador não existe. Crie uma conta para continuar.');
                    console.error('User not found');
                } else {
                    console.error('Login failed');
                }
            } catch (error) {
                if ((error as any).response.status === 404) {
                    toast.error('Utilizador não existe. Crie uma conta para continuar.');
                    console.error('User not found');
                } else {
                    toast.error('Ocorreu um erro. Tente iniciar sessão novamente com email e password.');
                    console.error('Login failed', error);
                }
            }

        } catch (err) {
            console.error(err);
        }
    };


    return (
        <div className='flex w-full h-full min-h-screen mil:bg-white bg-[var(--darkGray)] items-center'>
            <Toaster />
            <div className='w-full text-black dark-bg-image mil:w-[60rem] h-full'>
                <section className="flex flex-col items-center justify-center h-full space-y-10 md:flex-row">
                    <div className="flex flex-col justify-center w-full h-full gap-8 px-2 cinco:px-10 mile400:px-15">
                        <img src={Logo} alt="logo" className='w-[20rem] invert mil:invert-0 brightness-0 text-left' />
                        <h1 className='pl-6 text-2xl text-white mil:text-black'>Welcome to Multipark!</h1>
                        <div className='px-5 cinco:px-10 mile400:px-15'>
                            <form onSubmit={handleLogin} className='flex flex-col gap-6'>
                                <div className='flex flex-col gap-2'>
                                    <label className="text-lg font-semibold text-white mil:text-black">Email</label>
                                    <input
                                        {...register('email', { required: true })}
                                        className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder='| Email'
                                    />
                                    {errors.email && <p>Por favor preencha o email.</p>}
                                </div>
                                <div className="relative">
                                    <button
                                        tabIndex={-1}
                                        type='button'
                                        className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-12"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {!isPasswordVisible ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                    <div className='flex flex-col gap-2'>
                                        <label className="text-lg font-semibold text-white mil:text-black">Password</label>
                                        <input
                                            {...register('password', { required: true })}
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder='| Password'
                                            className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        />
                                        {errors.password && <p>Por favor preencha a password.</p>}
                                    </div>
                                </div>
                                {/* // TODO : Add remember me functionality 
                                <div className="flex justify-between text-sm font-semibold">
                                    <label className="flex cursor-pointer select-none text-slate-500 hover:text-slate-600">
                                        <input className="mr-1 text-2xl accent-green-400" tabIndex={-1} type="checkbox"
                                            checked={remember}
                                            onChange={(e) => {setRemember(e.target.checked.toString()); localStorage.setItem('remember', e.target.checked.toString())}} />
                                        <span className='text-white mil:text-black'>Remember Me</span>
                                    </label>
                                </div> */}
                                <div className="flex flex-col gap-4 text-center">
                                    <button type="submit" className="font-bold w-full text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-xl text-2xl px-5 py-3.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border-[1px] border-white ">Entrar</button>
                                    <button type="button" onClick={handleGoogleLogin} className="w-full justify-center inline-flex items-center px-5 py-3.5 text-center text-white bg-blue-700 rounded-xl hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 border-[1px] border-white">
                                        <span className="inline-flex items-center justify-center w-8 h-8 text-base font-semibold bg-transparent ms-2">
                                            <IoLogoChrome size={"32px"} />
                                        </span>
                                        <span className="ml-2 text-2xl font-bold">Google</span>
                                    </button>
                                </div>
                                <div className="text-lg font-semibold text-center text-white mil:text-black">
                                    <Link className='hover:underline underline-offset-4' to="https://www.multigroup.pt/add-property">
                                        Registar / Criar Conta
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <div className='relative items-center justify-center hidden w-full min-h-screen h-full mil:flex bg-[var(--primary)]'>
                <div className='flex items-center justify-center h-full w-[30rem]'>
                    <img src={carParking} alt="car parking" className='w-full' />
                </div>
            </div>
            <Loading isOpen={loading} />
        </div>
    );
}
