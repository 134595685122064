
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
// TODO -> icons for print, pdf and qr code, functionallities to be added
// import { MdLocalPrintshop } from "react-icons/md";
// import { VscFilePdf } from "react-icons/vsc";
// import { BsQrCode } from "react-icons/bs";
import { isMobile } from 'react-device-detect';

export default function BookingInfo({ booking }: { booking: any }) {
    function formatDate(date: any) {
        if (Date.parse(date)) {
            return new Date(date).toLocaleDateString('en-GB');
        } else {
            return date;
        }
    }

    return (
        <div>
            <div className="flex items-start flex-col cinco:flex-row cinco:items-center oito:items-start oito:flex-col mile100:flex-row gap-4 justify-between mile100:items-center pb-4">
                <div className='flex gap-4 items-center'>
                    <div>
                        <h4 className="font-bold">{booking.client.name}</h4>
                    </div>
                </div>
                <div className='flex gap-4 cinco:gap-10 items-center'>
                    <div className='bg-black p-2 rounded-full'>
                        {isMobile ?
                            <a href={`tel:${booking.client.phone}`}><FaPhone size={20} color="white" /></a> :
                            <a href={`mailto:${booking.client.email}`}><IoMdMail size={24} color="white" /></a>
                        }
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex items-center'>
                    <h4 className="font-semibold">Details</h4>
                </div>
                <p>Park: {booking.park}</p>
                <p>Price: {booking.bookingPrice}</p>
                <p>Services: {booking.extraServices.join(", ")}</p>
                <p>Check in: {formatDate(booking.checkIn) + " " + booking.checkInTime} </p>
                <p>Check out: {formatDate(booking.checkOut) + " " + booking.checkOutTime}</p>
            </div>
            {/*  // TODO : Add print, pdf and qr code functionality
            <div className="flex gap-4 pt-4">
                <div className='bg-black p-1 rounded-full'>
                    <MdLocalPrintshop className='cursor-pointer' size={32} color="white" />
                </div>
                <VscFilePdf className='cursor-pointer' size={38} />
                <BsQrCode className='cursor-pointer' size={36} />
            </div> */}
        </div>
    );
}

