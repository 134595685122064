import React, { ReactNode, createContext, useContext, useState } from 'react';

const PendingChangesContext = createContext<[string[], React.Dispatch<React.SetStateAction<string[]>>]>([[], () => {}]);

export const PendingChangesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [pendingChanges, setPendingChanges] = useState<string[]>([]);

    return (
        <PendingChangesContext.Provider value={[pendingChanges, setPendingChanges]}>
            {children}
        </PendingChangesContext.Provider>
    );
};

export const usePendingChanges = () => {
    const context = useContext(PendingChangesContext);
    if (context === undefined) {
      throw new Error('usePendingChanges must be used within a PendingChangesProvider');
    }
    return context;
  };
