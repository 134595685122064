

import { Transition } from "react-transition-group";


const Modal = ({ isOpen, children }: { isOpen: boolean, children: React.ReactNode }) => {
    const transitionDuration = 400;


    return (
        <Transition in={isOpen} timeout={transitionDuration} unmountOnExit>
            {(state) => (
                <>
                    <div
                        className={`fixed inset-0 z-[60] bg-white transition-opacity duration-${transitionDuration} ${state === 'entered' ? 'opacity-70' : 'opacity-0'}`}
                    ></div>
                    <div
                        className={`fixed inset-0 z-[70] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${state === "entering" ? "scale-0" : "scale-100"
                            }`}

                    >
                        <div className="absolute min-w-[40%] w-full h-full oito:w-auto oito:h-auto">
                            <div
                                className={`relative z-[80] bg-white oito:border-4 oito:border-solid oito:border-black w-full h-full oito:rounded-[40px] shadow-lg transition-transform duration-${transitionDuration} ${state === "entering" || state === "exiting" ? "scale-0" : "scale-100"
                                    }`}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Transition>
    );
};

export default Modal;