import React from 'react';

interface SelectSortProps {
    handleSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    sortOption: string;
    options?: string[];
    label?: string;
}

const SelectSort: React.FC<SelectSortProps> = ({ handleSortChange, sortOption, options=['week','value', 'name'], label="Sort by" }) => (
    <div className="flex items-center">
        {label && <p className='min-w-20'>{label}</p>}
        <div className='flex items-center w-full relative'>
            <select
                className="z-10 cursor-pointer block py-2.5 pl-4 pr-7 marker:text-lg bg-transparent appearance-none focus:text-black font-bold focus:outline-none focus:ring-0 transition duration-500 ease-in"
                onChange={handleSortChange}
            >
                { options.map(option => (
                    <option
                        key={option}
                        value={option}
                        style={{
                            fontWeight: sortOption === option ? 'bold' : 'normal',
                        }}
                    >
                        {option}
                    </option>
                ))}
            </select>
            <img className='w-2.5 absolute left-20' src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='%23000000' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/></svg>" alt="SVG arrow" />
        </div>
    </div>
);

export default SelectSort;