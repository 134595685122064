import React, { SetStateAction, Dispatch } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './phoneInput.scss';
interface Props {
  contact: string;
  setContact: Dispatch<SetStateAction<string>>;
}

const MyPhoneInput: React.FC<Props> = ({ contact, setContact }) => {
  return (
    <PhoneInput
      defaultCountry="pt"
      value={contact}
      onChange={(contact: SetStateAction<string>) => setContact(contact)}
      className='input-phone-number'
    />
  );
};

export default MyPhoneInput;