import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Page from "../page";
import L, { LatLngExpression } from "leaflet";
import "leaflet-draw";
import { useEffect, useRef, useState } from "react";
import "./homeMap.styles.scss";
import marker from "../../content/images/icons/parking.png"
import { useNavigate } from "react-router-dom";
import { Geocoder } from 'leaflet-control-geocoder';
import { IoArrowBack } from "react-icons/io5";
import { useUser } from "../../contexts/userInfo-context";

export default function HomeMap() {
  const navigate = useNavigate();
  const { user } = useUser();
  const activeGarages = sessionStorage.getItem('activeGarages') ? JSON.parse(sessionStorage.getItem('activeGarages')!) : [];

  const mapRef = useRef<L.Map | L.LayerGroup<any> | null>(null);

  const [garages, setGarages] = useState<any>(activeGarages.filter((garage: {address: undefined; }) => garage.address !== undefined));


  useEffect(() => {
    if (mapRef.current) {
      return;
    }
    //setting up the map
    const center = user!.garages ? [garages[0].address.latitude, garages[0].address.longitude] : [38.736946, -9.142685];
    mapRef.current = L.map("map", { attributionControl: false, zoomControl: false }).setView(center as LatLngExpression, 15);
    //setView recives center (lat, long -> garages[0]) and zoom level (15)


    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    }).addTo(mapRef.current);
    const map = mapRef.current;
    //setting up the markers
    const icon = L.icon({
      iconUrl: marker,
      iconSize: [70, 70],
    });

    const geocode = new Geocoder({
      collapsed: true,
      position: 'topright',
      placeholder: 'Pesquise Pelo Endereço',
      defaultMarkGeocode: false
    }).addTo(map);



    // handle geocoding result event
    geocode.on('markgeocode', e => {
      // coordinates for result
      const coords = [e.geocode.center.lat, e.geocode.center.lng];
      // center map on result
      map.setView(coords as LatLngExpression, 16);
    });

    garages.forEach((garage: any) => {
      const pos = [garage.address.latitude, garage.address.longitude];
      const marker = L.marker(pos as LatLngExpression, { icon }).addTo(map!);

      // setting up the popup

       const popupContent =
         `<div key=${garage.id}>
         <div class="flex justify-between">
           <div>
             <p class="text-base font-black">${garage.name}</p>
           </div>
         </div>
         <p class="text-base font-black">Morada:</p>
         <p class="text-sm font-black">${garage.address.address}</p>
         <div class="flex justify-center pt-6">
           <button id="navigate-${garage.name}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
             Ver mais
           </button>
         </div>
       </div>`;

      marker.bindPopup(popupContent);
      marker.on('popupopen', function () {
        const navigateButton = document.getElementById(`navigate-${garage.name}`);
        navigateButton?.addEventListener('click', () => navigate(`/home/garage/${garage.name}`));
      });
    });

  }, [garages]);


  return (
    <Page >
      <Header title='Garagens' />
      <div className='w-full min-h-full py-12 seis:px-[4vw] px-[8vw]'>
        <div className="text-lg font-extrabold w-fit">
          <Link to="/home" className="w-max flex"><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></Link>
        </div>
        <div className="homeMap w-full h-full pt-10 rounded-xl">
          <div id="map" />
        </div>
      </div>
    </Page>
  );
}