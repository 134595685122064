import Header from '../../components/Header';
import Page from '../page';
import { useEffect, useState } from 'react';
// import SelectSort from '../../components/SelectSort';
import { useUser } from '../../contexts/userInfo-context';
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading/loading';
import axios from 'axios';

export default function Billing() {
    const { garageId } = useParams<{ garageId: string }>();
    const { user } = useUser();
    const [garage, setGarage] = useState(user?.garages ? user?.garages.find((garage) => garage.name === garageId) : null);
    const [bookings, setBookings] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState<string[]>([]);
    
    useEffect(() => {
        setLoading(true)
        const activeGarages = sessionStorage.getItem('activeGarages');
        if (activeGarages) {
            const garages = JSON.parse(activeGarages);
            const options = garages.map((garage: { name: string; }) => garage.name);
            setOptions(options)
        } 
        setLoading(false)

    }, []);

    useEffect(() => {
        setLoading(true)
        const storedBookings = sessionStorage.getItem('bookings');
        if (storedBookings) {
            const bookingsSession = JSON.parse(storedBookings);
            setBookings(bookingsSession)
            setLoading(false)
        } else {
            handleClientsGetters()
        }
    }, [garageId]);


    const [allTimeParkingReservations, setAllTimeParkingReservations] = useState(0);
    const [monthlyParkingReservations, setMonthlyParkingReservations] = useState(0);
    const [yearParkingReservations, setYearParkingReservations] = useState(0);
    const [parkingValue, setParkingValue] = useState(0);
    const [parkingValueMonthy, setParkingValueMonthy] = useState(0);
    const [parkingValueYear, setParkingValueYear] = useState(0);

    function normalizeDate(dateString: string) {
        return dateString.replace(/"/g, '');
    }



     useEffect(() => {
         let garage = user?.garages ? user?.garages.find((garage) => garage.name === garageId) : null;
         if(!garage){
            garage = user?.garages ? user?.garages.find((garage) => garage.active) : null;
            
         }
         setGarage(garage);
         if (garage && bookings.length > 0) {
             const filteredBookings = bookings.filter((booking) => booking.garageId === garage._id);
             setAllTimeParkingReservations(filteredBookings.length);
             const monthlyBookings = filteredBookings.filter((booking) => new Date(normalizeDate(booking.date)).getMonth() === new Date().getMonth());
             const yearlyBookings = filteredBookings.filter((booking) => new Date(normalizeDate(booking.date)).getFullYear() === new Date().getFullYear());
             setMonthlyParkingReservations(monthlyBookings.length);
             setYearParkingReservations(yearlyBookings.length);
             setParkingValue(filteredBookings.reduce((acc, booking) => acc + booking.bookingPrice, 0)); // all time
             setParkingValueMonthy(monthlyBookings.reduce((acc, booking) => acc + booking.bookingPrice, 0));
             setParkingValueYear(yearlyBookings.reduce((acc, booking) => acc + booking.bookingPrice, 0));
         } 
     }, [garageId, bookings, garage]);


    const handleClientsGetters = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/booking/all`, {
                headers: {
                    'Authorization': `${user!.accessToken}`
                }
            });
            sessionStorage.setItem('bookings', JSON.stringify(response.data.data))
            const bookingsGetted = response.data.data;
            setBookings(bookingsGetted)
            
        } catch (error) {
            console.error('Error getting bookings:', error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Page >
            <Header title='Billing' value={garageId} options={options} />
            <div className='w-full seis:px-[4vw] px-[8vw] py-12'>
                <div className='flex flex-col w-full min-h-full pb-6 mile200:flex-row'>
                    <div className='flex flex-col w-full gap-8 mile200:w-2/5'>
                        <h1 className='w-full text-2xl font-bold text-[var(--primary)]'>Report Analysis</h1>
                        <div className='flex flex-col gap-10'>
                            <div className='flex flex-col w-full gap-4'>
                                <label className="text-2xl font-bold text-black">Local de Recebimento</label>
                                <input
                                    type="text"
                                    placeholder='Local de Recebimento'
                                    value={garage?.IBAN ? garage.IBAN : ''}
                                    disabled
                                    // onChange={(e) => set(e.target.value)}
                                    className='w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] bg-white outline-[var(--primary)]'
                                />
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <label className="text-2xl font-bold text-black">Nome do comprovativo</label>
                                <input
                                    type="text"
                                    placeholder='Local de Recebimento'
                                    value={user?.firstName + ' ' + user?.lastName}
                                    disabled
                                    // onChange={(e) => set(e.target.value)}
                                    className='w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] bg-white outline-[var(--primary)]'
                                />
                            </div>
                            {/* TODO <div className='w-full'>
                                <label className="text-2xl font-bold text-black">Levantado</label>
                                <div className='flex items-center justify-between w-full'>
                                    <p className="text-2xl font-bold text-start">{date}</p>
                                    <div>
                                        <button onClick={downloadPDF} className="flex items-center justify-center w-full px-4 py-1 text-xl text-center transition-transform duration-200 border-2 border-black border-solid rounded-full cursor-pointer select-none hover:scale-105">PDF</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='w-full mile200:w-3/5'>
                        <div className='pt-8'>
                            <h1 className='text-2xl font-bold mile200:pl-24 text-[var(--primary)]'>Revenue</h1>
                            <div className='pt-8 mile200:pl-20'>
                                {/* <div>
                                // TODO: revenue elétrico para implementaçao futura 
                                    <p className='text-xl font-bold text-start'>
                                        <span className='inline-block w-3 h-3 mr-2 bg-blue-500 border border-black border-solid rounded-full'></span>Electric charge
                                    </p>
                                    <div className='flex pt-6 pl-10'>
                                        <div className='w-3/5'>
                                            <p className='pb-4 text-xl font-bold text-start'>{`Time: ${allTimeElectricReservations} reservations`}</p>
                                            <p className='pb-4 text-xl font-bold text-start'>{`Monthly: ${monthlyElectricReservations} reservations`}</p>
                                        </div>
                                        <div className='flex items-center justify-end w-2/5'>
                                            <p className='text-2xl font-bold text-start'>{`+ ${electricValue}€`}</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='pt-8'>
                                    <p className='text-xl font-bold text-start'>
                                        <span className='inline-block w-3 h-3 mr-2 bg-yellow-500 border border-black border-solid rounded-full'></span>Parking
                                    </p>
                                    <div className='flex pt-6 pl-10'>
                                        <div className='w-3/5 flex flex-col gap-2'>
                                            <p className='text-xl font-bold text-start'>{`All Time: ${allTimeParkingReservations} reservations`}</p>
                                            <p className='text-xl font-bold text-start'>{`Monthly: ${monthlyParkingReservations} reservations`}</p>
                                            <p className='text-xl font-bold text-start'>{`Yearly: ${yearParkingReservations} reservations`}</p>
                                        </div>
                                        <div className='flex w-2/5 flex-col gap-2 items-end'>
                                            <p className='text-xl font-bold text-start'>{`+ ${parkingValue}€`}</p>
                                            <p className='text-xl font-bold text-start'>{`+ ${parkingValueMonthy}€`}</p>
                                            <p className='text-xl font-bold text-start'>{`+ ${parkingValueYear}€`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-10'>
                                    <div className='flex justify-end w-full'>
                                        <p className='pr-16 text-lg font-bold text-start'>
                                            Total
                                        </p>
                                        <div>
                                            <p className='w-full text-2xl font-black text-center'>{`+ ${parkingValueMonthy}€`}</p>
                                            <p className='w-full font-black text-center text-slate-500'>este mês</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Loading isOpen={loading} />

        </Page>
    );
}
