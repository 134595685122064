import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Page from "../page";
import {  useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { FaImage } from "react-icons/fa6";
import { IoDocumentTextOutline, IoArrowBack, IoTrashOutline } from "react-icons/io5";
import { Geocoder } from 'leaflet-control-geocoder';
import { TiPlus } from "react-icons/ti";
import toast, { Toaster } from 'react-hot-toast';
import AddCharger from "../../components/AddCharger";
import axios from "axios";
import { uploadToFirebase } from "../../firebase/firebase";
import { useUser } from "../../contexts/userInfo-context";
import Loading from "../../components/loading/loading";
import { Charger, Garage } from "../../components/interfaces/interfaces";
import MyPhoneInput from "../../components/phoneInput/PhoneInput";
import { isPhoneValid, validateEmail } from "../../components/ValidationUtils";

export default function CreateGarage() {
    const { user, setUser } = useUser();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [address, setAddress] = useState('');
    const [totalSpots, setTotalSpots] = useState('');
    const [nif, setNif] = useState('');
    const [iban, setIban] = useState('');
    const [haveCharger, setHaveCharger] = useState(false);
    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
    const [pdfFile, setSelectedPDF] = useState<File | null>(null);
    const [logoFile, setSelectedLogo] = useState<File | null>(null);
    const [popup, setPopup] = useState(false);
    const [chargerType, setChargerType] = useState<Charger[]>([]);
    const [loading, setLoading] = useState(false);


    // check if images are of valid type and count (png, jpg, max 10 images)
    function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        if (event.target.files) {
            const fileNames = [];
            for (let i = 0; i < event.target.files.length; i++) {
                let file = event.target.files[i];
                let fileType = file["type"];
                let validImageTypes = ["image/jpg", "image/png"];
                if (!validImageTypes.includes(fileType)) {
                    toast.error("Tipo inválido. Apenas .png e .jpg são válidos.");
                    event.target.value = "";
                    return;
                }
            }
            if (event.target.files.length > 10) {
                toast.error("Apenas pode adicionar até 10 imagens.");
                event.target.value = "";
            } else {
                setSelectedImages(event.target.files);

                for (let i = 0; i < event.target.files.length; i++) {
                    fileNames.push(event.target.files[i].name);
                }
                toast.success("Imagens selecionadas: " + fileNames.join(", "), { duration: 5000 });
                event.target.value = "";
            }
        }
    }

    // check if file are of valid type and size (pdf, max 250MB)
    function handlePDFUpload(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            const fileSizeInMB = event.target.files[0].size / (1024 * 1024);
            if (fileSizeInMB > 250) {
                toast.error("Pdf tem de ter menos de 250MB");
                event.target.value = "";
            } else {
                setSelectedPDF(event.target.files[0]);
                toast.success("Selected PDF: " + event.target.files[0].name, { duration: 3000 });
                event.target.value = "";
            }
        }
    }

    // check if logo is of valid type(png, jpg)
    function handleLogoUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            let fileType = event.target.files[0]["type"];
            let validImageTypes = ["image/jpg", "image/png"];
            if (!validImageTypes.includes(fileType)) {
                toast.error("Tipo inválido. Apenas .png e .jpg são válidos.");
                event.target.value = "";
                return;
            }

            setSelectedLogo(event.target.files[0]);
            toast.success("Logo selecionado: " + event.target.files[0].name, { duration: 3000 });
            event.target.value = "";
        }
    }

    useEffect(() => {
        if (popup) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px'; //Padding to prevent page from shifting
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
        }
    }, [popup]);


    const handleCreate = async () => {
        if (!name || !email || !contact || !nif || !totalSpots || !selectedImages || !pdfFile) {
            toast.error('Preencha todos os campos obrigatórios.');
            return;
        }

        if (isNaN(Number(nif)) || isNaN(Number(contact)) || isNaN(Number(totalSpots))) {
            toast.error('Os campos NIF, contacto, e totalSpots têm de ser números.');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Email inválido.');
            return;
        }

        if (!isPhoneValid(contact)) {
            toast.error('Contacto inválido.');
            return;
        }

        setLoading(true);



        const uploadedPdf = pdfFile ? await uploadToFirebase([pdfFile], 'garages/pdfs', user!.email) : null;
        const uploadedImages = selectedImages ? await uploadToFirebase(Array.from(selectedImages), 'garages/images', user!.email) : null;
        const uploadedLogo = logoFile ? await uploadToFirebase([logoFile], 'garages/logos', user!.email) : null;

        if (!uploadedImages || !uploadedPdf || (logoFile && !uploadedLogo)) {
            toast.error('Erro ao fazer upload dos ficheiros. Tente novamente.');
            setLoading(false);
            return;
        }

        const geocode = new Geocoder({
            collapsed: true,
            position: 'topright',
            placeholder: 'Pesquise Pelo Endereço',
            defaultMarkGeocode: false
        })



        const getLatLng = new Promise((resolve, reject) => {
            if (geocode.options.geocoder) {
                geocode.options.geocoder.geocode(address, (results) => {
                    const latlng = results[0].center;
                    resolve(latlng);
                });
            } else {
                reject(new Error('Geocoder not initialized'));
            }
        });

        let latlng;
        try {
            latlng = await getLatLng;
        } catch (error) {
            toast.error('Localização não encontrada. Tente uma localização menos específica.', {
                duration: 5000
            });
            console.error(error);
            return;
        }


        let data: Garage = {
            name: name,
            email: email,
            phoneNumber: contact,
            NIF: nif,
            IBAN: iban,
            images: uploadedImages,
            certificate: uploadedPdf![0],
            totalSpots: Number(totalSpots),
            status: 'staging',
            address: {
                address: address,
                latitude: Number((latlng as any).lat),
                longitude: Number((latlng as any).lng)
            }
        };

        if (uploadedLogo && uploadedLogo.length > 0) {
            data = {
                ...data,
                logo: uploadedLogo[0]
            }
        }

        if (chargerType.length > 0) {
            data = {
                ...data,
                chargers: chargerType
            }
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/garage/create`, data, {
                headers: {
                    'Authorization': user!.accessToken
                }
            });


            if (response.data.condition === "success") {
                setSelectedImages(null);
                setSelectedPDF(null);
                setSelectedLogo(null);
                setName('');
                setEmail('');
                setContact('');
                setAddress('');
                setTotalSpots('');
                setNif('');
                setIban('');
                setChargerType([]);
                data.id = response.data.data;
                if (user!.garages) {
                    setUser({ ...user!, garages: [...user!.garages, data] })
                } else {
                    setUser({ ...user!, garages: [data] })
                }

                toast.success('Garagem criada com sucesso!');

            }
        } catch (error) {
            toast.error('Erro ao criar garagem. Tente novamente.');
            console.error('Error creating garage:', error);
        }
        setLoading(false);
    }




    return (
        <Page >
            <Toaster />
            <Header title='My Garage' />
            <div className='w-full p-8'>
                <div className="text-lg font-extrabold w-fit">
                    <Link to="/home" className="w-max flex"><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></Link>
                </div>
                <div className="px-10 py-10">
                    <p className="text-2xl font-black text-[var(--primary)]">Nova Garagem</p>
                </div>
                <div className='flex flex-col w-full min-h-full pb-6 mile200:flex-row'>
                    <div className='w-full sete:px-14 mile200:w-1/2'>
                        <div className='w-11/12 mile200:w-5/6 mile400:w-4/5'>
                            <div className='py-4'>
                                <label className="text-lg font-black text-black">NOME*</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="Nome"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">EMAIL*</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">CONTACTO*</label>
                                <div className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-2 px-6 rounded-full mt-2 font-medium bg-white">
                                    <MyPhoneInput contact={contact} setContact={setContact} />
                                </div>

                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">NIF/Nº CONTRIBUINTE*</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="Nif/Nº Contribuinte"
                                    value={nif}
                                    onChange={(e) => setNif(e.target.value)}
                                />
                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">IBAN*</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="IBAN"
                                    value={iban}
                                    onChange={(e) => setIban(e.target.value)}
                                />
                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">MORADA DA GARAGEM</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="Morada da Garagem"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className='py-4'>
                                <label className="text-lg font-bold text-black">Nº DE LUGARES*</label>
                                <input
                                    className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-3 px-6 rounded-full mt-2 font-medium bg-white"
                                    placeholder="Nº de Lugares"
                                    value={totalSpots}
                                    onChange={(e) => setTotalSpots(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='w-full pt-10 mile200:w-1/2 sete:px-14 mile200:px-0 mile200:pt-0'>
                        <p className='text-lg font-black text-black'>DOCUMENTOS NECESSÁRIOS</p>
                        <div className='pt-6'>
                            <div className="flex gap-14">
                                <div>
                                    <p className='text-lg font-bold text-start'>
                                        <span className='inline-block w-2 h-2 mb-1 mr-2 bg-black border border-black border-solid rounded-full'></span>IMAGENS GERAIS*
                                    </p>
                                    <div className='flex pt-3 pl-4'>
                                        <p className='pb-4 text-sm text-start'>MÁXIMO DE 10 (FORMATO PNG OU JPG)</p>
                                    </div>
                                </div>
                                <div className="relative">
                                    <label title="Click to upload" htmlFor="buttonImages" className="relative z-10 cursor-pointer hover:scale-105 transition-transform duration-200 flex bg-[var(--primary)] px-4 py-2 rounded-xl gap-3 min-w-40">
                                        <label htmlFor="buttonImages" className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer">
                                            UPLOAD
                                            <FiUpload color="white" size={20} />
                                            <FaImage color="white" size={20} />
                                        </label>
                                    </label>
                                    <input hidden type="file" name="buttonImages" id="buttonImages"
                                        className="w-full"
                                        accept=".png, .jpg"
                                        multiple
                                        onChange={handleImageUpload}
                                    />
                                </div>
                            </div>
                            <div className='pt-8'>
                                <div className="flex gap-8">
                                    <div>
                                        <p className='text-lg font-bold text-start'>
                                            <span className='inline-block w-2 h-2 mb-1 mr-2 bg-black border border-black border-solid rounded-full'></span>CERTIDÃO PREDIAL DA GARAGEM*
                                        </p>
                                        <div className='flex pt-3 pl-4'>
                                            <p className='pb-4 text-sm text-start'>(FORMATO PDF - MAX 250MB)</p>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <label title="Click to upload" htmlFor="buttonPdf" className="relative z-10 cursor-pointer hover:scale-105 transition-transform duration-200 flex bg-[var(--primary)] px-4 py-2 rounded-xl gap-3 min-w-40">
                                            <label htmlFor="buttonPdf" className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer">
                                                UPLOAD
                                                <FiUpload color="white" size={20} />
                                                <IoDocumentTextOutline color="white" size={20} />
                                            </label>
                                        </label>
                                        <input hidden type="file" name="buttonPdf" id="buttonPdf"
                                            className="w-full"
                                            accept=".pdf"
                                            onChange={handlePDFUpload} />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-10'>
                                <p className='text-sm font-black text-black'>OPCIONAL:</p>
                                <div className="flex gap-2 pt-8">
                                    <p className='text-sm font-black text-black max-w-36'>
                                        LOGÓTIPO DA GARAGEM
                                    </p>
                                    <label className='flex items-center hover:scale-105 transition-transform duration-200 bg-[var(--primary)] px-4 py-2 rounded-xl'>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept=".png, .jpg"
                                            onChange={handleLogoUpload}
                                        />
                                        <FiUpload color="white" size={20} />
                                    </label>
                                </div>
                                <div className="pt-10">
                                    <label className="flex cursor-pointer select-none">
                                        <p className='text-sm font-black text-black'>JÁ TENHO CARREGADOR ELÉTRICO</p>
                                        <input className="ml-2 w-5 h-5 rounded-[5px] border border-solid border-black" type="checkbox"
                                            checked={haveCharger}
                                            size={40}
                                            onChange={(e) => setHaveCharger(e.target.checked)} />
                                    </label>
                                </div>
                                <div className="flex pt-10 items-center gap-4 pb-8">
                                    <button
                                        onClick={() => {

                                            setPopup(true);

                                        }}
                                        className={`gap-2 transition-all duration-500 flex justify-center ease-in-out bg-[var(--primary)] hover:scale-105 px-4 py-4 rounded-[40px]`}
                                    >
                                        <p className="text-sm font-bold text-white">ADICIONAR CARREGADOR</p>
                                        <TiPlus color="white" size={20} />
                                    </button>

                                    {chargerType.length > 0 &&
                                        <IoTrashOutline
                                            onClick={() => {
                                                // Remove the last item from the array
                                                setChargerType(chargerType.slice(0, -1));
                                                toast.success(`Carregador do tipo ${chargerType[chargerType.length - 1].type} removido.`);
                                            }}
                                            className="cursor-pointer transition-all duration-200 ease-in-out hover:scale-110"
                                            title="Remover"
                                            color="red"
                                            size={40}
                                        />
                                    }

                                </div>
                                {chargerType.length > 0 && <div className="text-sm font-bold text-black">
                                    <p>Adicionado(s) os carregador(es):</p>
                                    {chargerType.map((charger, index) => (
                                        <p key={index} >{charger.type}</p>
                                    ))}</div>}
                            </div>
                        </div>
                    </div>

                </div>
                <p className="sete:px-14 text-zinc-500">Campos com * são de preenchimento obrigatório</p>
                <div className="w-full flex flex-col items-center ">
                    <button onClick={handleCreate} className='hover:scale-105 transition-transform duration-200 flex justify-center bg-[var(--primary)] px-4 py-2 rounded-lg mt-10 mb-12'>
                        <p className="text-sm font-bold text-white">Listar Garagem</p>
                    </button>
               </div>
            </div>
            <p className='pt-20 pb-8 p-4 uppercase text-[1.2rem] oito:text-[1.5rem]'><span className='font-garetBold'>Nota:</span> Qualquer questão envie para <a href="mailto:partners@multipark.pt" className='font-garetBold hover:underline'>partners@multipark.pt</a></p>
            <AddCharger popup={popup} setPopup={setPopup} chargers={chargerType} setChargers={setChargerType} />
            <Loading isOpen={loading} />
        </Page>
    );
}

