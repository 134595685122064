import { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import Page from '../page';
import clients from "../../content/jsons/clients.json";
import { IoArrowBack } from "react-icons/io5";
import { Link, useParams } from 'react-router-dom';
import { Transition } from "react-transition-group";
 import { FaPhone } from "react-icons/fa6";
import SelectSort from '../../components/SelectSort';
import { MdLocalPrintshop } from "react-icons/md";
import { VscFilePdf } from "react-icons/vsc";
import { BsQrCode } from "react-icons/bs";
import { toast, Toaster } from 'react-hot-toast';

const defaultStyle = {
    transition: `all 200ms ease-in-out`,
    opacity: 0,
    with: '100%',
}

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

//TODO not used for this dashboard implementation

export default function Clients() {
    const { garageId } = useParams<{ garageId: string }>();
    
    const [search, setSearch] = useState('');
    //set default to first item
    const [selected, setSelected] = useState(clients[0]);
    const [filteredClients, setFilteredClients] = useState(clients);
    const [options, setOptions] = useState(selected.bookings.map((booking) => (`Book ${booking.id}`)));
    const [sortOption, setSortOption] = useState(options[0]);
    const [transition, setTransition] = useState(false);
    const [change, setChange] = useState(false);

    const selectGarageFilter = () => {
        const result: any[] = []; // Initialize an empty array
        if(!garageId){
            return clients;
        }
        clients.forEach(client => {
            var filteredGarage = client.bookings.filter(booking => booking.garageID.toString() === garageId);
            if (filteredGarage.length > 0) {
                result.push({ ...client, bookings: filteredGarage });
            }
        });
        return result;
    }
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const result = clients.filter(client =>
            client.id === Number(search) ||
            client.name.toLowerCase().includes(search.toLowerCase())
        );
        if (result.length === 0) {
            toast.error(`Não existem resultados para a pesquisa: ${search}`);
        } else if (result.length === 1) {
            setChange(!change);
            setTimeout(() => setSelected(result[0]), 200);
        }
        setFilteredClients(result);
    };



    const handleSortChange = (event: any) => {
        setChange(!change)
        setTimeout(() => setSortOption(event.target.value), 200);
    }

    useEffect(() => {
        setTransition(false);
        setTimeout(() => setTransition(true), 200);
    }, [change]);

    useEffect(() => {
        setFilteredClients(selectGarageFilter);
    }, [garageId]);


    return (
        <Page >
            <Toaster />
            <Header title='Clients' options={clients.map((client: { id: number; }) => client.id.toString())} />
            <div className="pt-12 seis:px-[4vw] px-[8vw] w-fit">
                <Link to={garageId !== "All" ? `/clients/${garageId}` : `/clients`} className='w-fit'>
                    <div className="flex text-lg font-extrabold w-fit cursor-pointer "><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></div>
                </Link>
            </div>
            <div className="py-12 seis:px-[4vw] px-[8vw] 2xl:flex gap-4">
                <div className="grid grid-cols-1 oito:grid-cols-4 mile100:grid-cols-5 gap-8">

                    <div className="flex flex-col col-span-2 gap-8">
                        <div className="flex gap-10">
                            <form action="/search" className="w-full" onSubmit={handleSearchSubmit}>
                                <div className="relative w-full">
                                    <input type="text" name="search" placeholder='Search' className="border border-solid rounded-xl border-zinc-400 w-full h-12 p-4 bg-transparent dark:text-gray-800 placeholder-zinc-400" onChange={handleSearchChange} value={search} />
                                    <button type="submit"></button>
                                </div>
                            </form>
                            <Link to={garageId !== "All" ? `/clients/${garageId}` : `/clients`} className="bg-blue-500 text-white px-4 py-2 rounded-xl w-60 flex justify-center items-center font-bold text-xl transition-all hover:scale-110 ease-in-out duration-300">
                                List
                            </Link>
                        </div>
                        <div className="space-y-4">
                            {filteredClients.map((client, index) => (
                                <div key={index} onClick={() => {setChange(!change);setTimeout(() => setSelected(client), 200)}} className={`p-4 border border-solid border-black rounded-3xl flex items-center gap-4 transition-all ease-in-out duration-500 cursor-pointer ${selected === client ? "bg-blue-200" : "bg-transparent"}`}>
                                    <img src={client.image} alt={client.name} className="rounded-2xl border border-solid border-black" />
                                    <div>
                                        <h4 className="font-bold">{client.name}</h4>
                                        <p>{client.email}</p>
                                        <p>{client.phone}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-2 mile100:col-span-3 flex justify-between flex-col p-8  border border-solid border-black  rounded-3xl">
                        <Transition in={transition}  timeout={200}>
                            {state => (
                                <div style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state as keyof typeof transitionStyles]
                                }}>

                                    {/* <div className='border border-solid border-black p-4 rounded-3xl h-full'> */}
                                    <div className="flex items-start flex-col cinco:flex-row cinco:items-center oito:items-start oito:flex-col mile100:flex-row gap-4 justify-between mile100:items-center pb-4">
                                        <div className='flex gap-4 items-center'>
                                            <img src={selected.image} alt={selected.name} className="rounded-2xl border border-solid border-black" />
                                            <div>
                                                <h4 className="font-bold">{selected.name}</h4>
                                            </div>
                                        </div>
                                        <div className='flex gap-4 cinco:gap-10 items-center'>
                                            <div className='flex items-center border border-solid border-black rounded-full w-32 pl-2 h-10'>
                                                <SelectSort handleSortChange={handleSortChange} sortOption={sortOption} label='' options={options} />
                                            </div>
                                            <div className='bg-black p-2 rounded-full'>
                                                <a href='tel:+351967009861'><FaPhone size={20} color="white" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex items-center'>
                                            <h4 className="font-semibold">Details</h4>
                                        </div>
                                        {selected.bookings.filter(booking => booking.id === Number(sortOption.replace("Book ", ""))).map((booking, index) => (
                                            <div key={index}>
                                                <p>Booking: #{booking.id}</p>
                                                <p>Park: {booking.park}</p>
                                                <p>Price: {booking.bookingPrice}</p>
                                                <p>Services: {booking.extraServices.join(", ")}</p>
                                                <p>Check in: {booking.checkIn + " " + booking.checkInTime} </p>
                                                <p>Check out: {booking.checkOut + " " + booking.checkOutTime}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex gap-4 pt-4">
                                        <div className='bg-black p-1 rounded-full'>
                                            <MdLocalPrintshop size={32} color="white" />
                                        </div>
                                        <VscFilePdf size={38} />
                                        <BsQrCode size={36} />
                                    </div>
                                    {/* </div>
                        <div className='p-4 flex items-center gap-4'>
                            <div>
                                <IoIosAdd size={58} className='transiton-all ease-in-out hover:scale-105' />
                            </div>
                            <input type="text" name="message writting" placeholder='Write here' className="border border-solid rounded-xl border-zinc-400 w-full h-12 p-4 bg-transparent dark:text-gray-800 placeholder-transparent seis:placeholder-zinc-400" onChange={(e) => setMessage(e.target.value)} value={message} />
                        </div> */}
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>
            </div>
        </Page>
    );
}

