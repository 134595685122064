import Header from '../../components/Header';
import Page from '../page';

import { useEffect, useState } from 'react';
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, Cell, PieChart, Pie } from 'recharts';
import axios from 'axios';
import { useUser } from '../../contexts/userInfo-context';
import Loading from '../../components/loading/loading';
import { ChartData } from '../../components/interfaces/interfaces';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
export default function Statistics() {
    const { user } = useUser();
    const [reservations, setReservations] = useState({});
    const [earnings, setEarnings] = useState({});
    const [topGarages, setTopGarages] = useState<{ totalValue: number; reservations: number; name: string; }[]>([]);
    const [topClients, setTopClients] = useState<{ totalValue: number; reservations: number; name: string; }[]>([]);
    const [garageChartData, setGarageChartData] = useState<ChartData[]>([]);
    const [clientChartData, setClientChartData] = useState<ChartData[]>([]);
    const [reservationHistory, setReservationHistory] = useState<{ date: string; reservas: number }[]>([]);
    const [valuesHistory, setValuesHistory] = useState<{ date: string; earning: number }[]>([]);
    const [bookings, setBookings] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const handleClientsGetters = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/booking/all`, {
                headers: {
                    'Authorization': `${user!.accessToken}`
                }
            });
            sessionStorage.setItem('bookings', JSON.stringify(response.data.data))
            const bookingsGetted = response.data.data;
            setBookings(bookingsGetted);

        } catch (error) {
            console.error('Error getting bookings:', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        const storedBookings = sessionStorage.getItem('bookings');
        if (storedBookings) {
            const bookingsSession = JSON.parse(storedBookings);
            setBookings(bookingsSession);
        } else {
            handleClientsGetters()
        };

    }, []);

    function normalizeDate(dateString: string) {
        return dateString.replace(/"/g, '');
    }

    /* 
        function that calculates all the statistics
        merged all in this function to avoid multiple loops through the bookings array
        in case it takes to long to load the loading makes the user aware that the page is loading
    */
    const getTopGarages = () => {
        let garageTotals: { [key: string]: { totalValue: number, reservations: number } } = {};
        let reservations: { today: number, week: number, month: number, all_Time: number } = { today: 0, week: 0, month: 0, all_Time: 0 };
        let earnings: { today: number, week: number, month: number, all_Time: number } = { today: 0, week: 0, month: 0, all_Time: 0 };
        let clientTotals: { [key: string]: { totalValue: number, reservations: number } } = {};
        const reservationsPerDay: { [date: string]: number } = {};
        const reservationsPerDayValue: { [date: string]: number } = {};
        let todayDate = new Date();
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(todayDate.getDate() - 7);
        let oneMonthAgo = new Date();
        oneMonthAgo.setMonth(todayDate.getMonth() - 1);

        for (let booking of bookings) {
            let bookingDate = new Date(normalizeDate(booking.date)); // assuming booking.date is in ISO format
            const reservationHistory: { time: string; reservations: number }[] = []; // Define the type of reservationHistory array

            reservationHistory.push({ time: booking.date, reservations: reservations.all_Time });

            if (!garageTotals[booking.park]) {
                garageTotals[booking.park] = { totalValue: 0, reservations: 0 };
            }
            garageTotals[booking.park].totalValue += booking.bookingPrice;
            garageTotals[booking.park].reservations += 1;
            reservations.all_Time += 1;
            earnings.all_Time += booking.bookingPrice;

            if (bookingDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
                reservations.today += 1;
                earnings.today += booking.bookingPrice;
            }

            if (bookingDate >= oneWeekAgo) {
                reservations.week += 1;
                earnings.week += booking.bookingPrice;
            }

            if (bookingDate >= oneMonthAgo) {
                reservations.month += 1;
                earnings.month += booking.bookingPrice;
            }

            if (!clientTotals[booking.client.name]) {
                clientTotals[booking.client.name] = { totalValue: 0, reservations: 0 };
            }
            clientTotals[booking.client.name].totalValue += booking.bookingPrice;
            clientTotals[booking.client.name].reservations += 1;

            const date = booking.date;
            reservationsPerDay[date] = (reservationsPerDay[date] || 0) + 1;
            reservationsPerDayValue[date] = (reservationsPerDayValue[date] || 0) + booking.value;
        }

        let sortedGarages = Object.entries(garageTotals).sort((a, b) => b[1].totalValue - a[1].totalValue);
        setReservations(reservations);
        setEarnings(earnings);
        setTopGarages(sortedGarages.slice(0, 3).map(garage => ({ name: garage[0], ...garage[1] })));
        setGarageChartData(sortedGarages.slice(0, 3).map(garage => ({ name: garage[0], TotalValue: garage[1].totalValue })));
        setReservationHistory(reservationHistory);
        let sortedClients = Object.entries(clientTotals).sort((a, b) => b[1].totalValue - a[1].totalValue);

        setTopClients(sortedClients.slice(0, 3).map(client => ({ name: client[0], ...client[1] })));
        setClientChartData(sortedClients.slice(0, 3).map(client => ({ name: client[0], TotalValue: client[1].totalValue })));
        const result = Object.entries(reservationsPerDay).map(([date, count]) => ({
            date: date,
            reservas: count,
        }));

        setReservationHistory(result);

        const resultValues = Object.entries(reservationsPerDay).map(([date, value]) => ({
            date: date,
            earning: value,
        }));
        setValuesHistory(resultValues);
    }


    useEffect(() => {
        if(bookings.length === 0) return;
        setLoading(true);
        getTopGarages();
        setLoading(false);
    }, [bookings]);


    return (
        <Page >
            <Header title='Statistics' />
            <div className='flex w-full py-12 seis:px-[4vw] px-[8vw] mil:flex-row flex-col gap-10'>
                <div className='w-full flex flex-col gap-8'>
                    <p className='text-2xl font-bold'>Top 3 Garagens</p>
                    <div className='grid grid-cols-1 mile200:grid-cols-5 gap-8'>
                        <div className='flex gap-8 flex-col mile200:col-span-2'>
                            {topGarages.map((garage, index) => (
                                <div key={index} className='border border-solid border-black rounded-xl px-4 py-4 bg-zinc-100'>
                                    <p className='text-lg  text-[var(--primary)]  font-bold'>{garage.name}</p>
                                    <p>Reservas: {garage.reservations}</p>
                                    <p>Ganhos: {garage.totalValue}</p>
                                </div>
                            ))}
                        </div>
                        <div className='mile200:col-span-3'>
                            <ResponsiveContainer className="bg-zinc-100 border border-solid border-black rounded-2xl p-4" minHeight={"20vh"} height='100%' width='100%'>
                                <PieChart>
                                    <Pie dataKey="TotalValue" data={garageChartData} fill="#8884d8">
                                        {
                                            garageChartData!.map((entry: any, index: number) => (
                                                <Cell style={{ outline: "none" }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <p className='text-2xl font-bold'>Top 3 Clientes</p>
                    <div className='grid grid-cols-1 mile200:grid-cols-5 gap-8'>
                        <div className='mile200:col-span-3'>
                            <ResponsiveContainer className="bg-zinc-100 border border-solid border-black rounded-2xl p-4" minHeight={"20vh"} height='100%' width='100%'>
                                <PieChart>
                                    <Pie dataKey="TotalValue" data={clientChartData} fill="#8884d8">
                                        {
                                            clientChartData!.map((entry: any, index: number) => (
                                                <Cell style={{ outline: "none" }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='flex gap-8 flex-col mile200:col-span-2'>
                            {topClients.map((client, index) => (
                                <div key={index} className='border border-solid border-black rounded-xl px-4 py-4 bg-zinc-100'>
                                    <p className='text-lg text-[var(--primary)] font-bold'>{client.name}</p>
                                    <p>Reservas: {client.reservations}</p>
                                    <p>Ganhos: {client.totalValue}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className='text-2xl font-bold'>Reservas</p>

                    <div className='grid grid-cols-1 mile200:grid-cols-5 gap-8'>
                        <div className='mile200:col-span-3'>
                            <ResponsiveContainer className="bg-zinc-100 border border-solid border-black rounded-2xl p-4" minHeight={"20vh"} height='100%' width='100%'>
                                <LineChart
                                    margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
                                    data={reservationHistory} 
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line dataKey="reservas" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='mile200:col-span-2'>
                            <div className='grid grid-cols-2 gap-10 mile400:gap-20 h-full'>
                                {Object.entries(reservations).map(([key, value], index) => (
                                    <div key={index} className='w-full flex justify-center items-start gap-4 flex-col h-full border border-solid border-black rounded-xl px-10 py-10 bg-zinc-100'>
                                        <p className='text-lg  text-[var(--primary)]  font-bold'>{value as React.ReactNode}</p>
                                        <p>{key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <p className='text-2xl font-bold pt-8'>Ganhos</p>
                    <div className='grid grid-cols-1 mile200:grid-cols-5 gap-8'>
                        <div className='mile200:col-span-3'>
                            <ResponsiveContainer className="bg-zinc-100 border border-solid border-black rounded-2xl p-4" minHeight={"20vh"} height='100%' width='100%'>
                                <LineChart
                                    margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
                                    data={valuesHistory} 
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line dataKey="earning" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='mile200:col-span-2'>
                            <div className='grid grid-cols-2 gap-10 mile400:gap-20 h-full'>
                                {Object.entries(earnings).map(([key, value], index) => (
                                    <div key={index}  className='w-full flex justify-center items-start gap-4 flex-col h-full border border-solid border-black rounded-xl px-10 py-10 bg-zinc-100'>
                                    <p className='text-lg  text-[var(--primary)]  font-bold'>{value as React.ReactNode}€</p>
                                        <p>{key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loading isOpen={loading} />
        </Page>
    );
}
