import Header from "../../../components/Header";
import Page from "../../page";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PreviewCard from "../components/PreviewCard";
import ServiceIcon from "../components/ServiceIconMapper";
//carousel
import CarouselGarage from "../components/Carousel";
//map
import "./preview.styles.scss";
import { Geocoder } from 'leaflet-control-geocoder';
import marker from "../../../content/images/icons/parking.png"
import L, { LatLngExpression } from "leaflet";
import "leaflet-draw";
//icons
import { IoArrowBack } from "react-icons/io5";
import { BsClockFill } from "react-icons/bs";
import { TbInfoCircleFilled } from "react-icons/tb";

import { useUser } from "../../../contexts/userInfo-context";
import { Schedule } from "../../../components/interfaces/interfaces";



export default function Preview() {
    const [imagesUrls, setImagesUrls] = useState([]);
    const { user } = useUser();

    const { garageId } = useParams<{ garageId: string }>();

    const mapRef = useRef<L.Map | L.LayerGroup<any> | null>(null);
    //garages examples for testing purposes

    const markerMap = useRef<L.Marker | null>(null);
    const [address, setAddress] = useState("Portugal");
    const [addressCoords, setAddressCoords] = useState<[number, number]>([35, 35]);
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [activeServices, setActiveServices] = useState<string[]>([]);
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [numberChargers, setNumberChargers] = useState(0);
    const [options, setOptions] = useState<string[]>([]);
    
    useEffect(() => {
        const activeGarages = sessionStorage.getItem('activeGarages');
        if (activeGarages) {
            const garages = JSON.parse(activeGarages);
            const options = garages.map((garage: { name: string; }) => garage.name);
            setOptions(options)
        } 

    }, []);


    const icon = L.icon({
        iconUrl: marker,
        iconSize: [70, 70],
    });

    const displayChargers = (nChargers: number) => {
        if (nChargers > 1) {
            return `${nChargers} Caregadores disponiveis`;
        } else if (nChargers === 1) {
            return `${nChargers} Caregador disponivel`;
        } else {
            return `Sem Caregadores disponiveis`;
        }
    }


    useEffect(() => {
        // Get values from session storage when component mounts
        const storedData = sessionStorage.getItem(`garage${garageId}`);
        if (storedData) {
            const data = JSON.parse(storedData);
            setImagesUrls(data.images);
            setAddressCoords([data.address.latitude, data.address.longitude])
            setAddress(data.address.address);
            setContact(data.phoneNumber);
            setEmail(data.email);
            if (data.chargers)
                setNumberChargers(data.chargers.length);
        }
    }, [garageId]);



    useEffect(() => {


        if (mapRef.current) {
            return;
        }


        //setting up the map
        const center = [
            32.778760,
            -6.167488
        ];
        mapRef.current = L.map("map", { attributionControl: false, zoomControl: false, doubleClickZoom: false }).setView(center as LatLngExpression, 15);

        const map = mapRef.current;

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        }).addTo(map);

        /*
        Geocoder for the map, allows to search for a location based on the address
        (conversion of address to coordinates)
        */
        const geocode = new Geocoder({
            collapsed: true,
            position: 'topright',
            placeholder: 'Pesquise Pelo Endereço',
            defaultMarkGeocode: false
        }).addTo(map);

        if (geocode.options.geocoder) {
            geocode.options.geocoder.geocode(address, (results) => {
                const latlng = results[0].center;
                setAddressCoords([latlng.lat, latlng.lng]);
                map.setView(latlng, 16);
                markerMap.current = L.marker(map.getCenter(), { icon }).addTo(map);
                markerMap.current.bindPopup(address);
            });
        }

        /* 
        conversion of address to coordinates based on search
        (as default it is used the address)
        */
        geocode.on('markgeocode', e => {
            const coords = [e.geocode.center.lat, e.geocode.center.lng];
            map.setView(coords as LatLngExpression, 16);//center map based on the searched location
        });

        /*
        SETUP MAP
        add marker to the address of the garage
        */

    }, [address]);


    useEffect(() => {
        if (mapRef.current && mapRef.current instanceof L.Map) {
            mapRef.current.setView(addressCoords as LatLngExpression, 16);
            if (markerMap.current) {
                mapRef.current.removeLayer(markerMap.current);
                markerMap.current = L.marker(addressCoords, { icon }).addTo(mapRef.current);
                markerMap.current.bindPopup(address);
            }
        }
    }, [addressCoords]);




    return (
        <Page >
            <Header title='Preview' value={garageId} options={options} />
            <div className="pt-12 seis:px-[4vw] px-[8vw]">
                <Link to={`/home/garage/${garageId}`}>
                    <div className="flex text-lg font-extrabold w-fit cursor-pointer "><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></div>
                </Link>
            </div>
            <div className="py-12 seis:px-[4vw] px-[8vw] 2xl:flex gap-4 min-h-full">
                <div className="w-full 2xl:w-[70%]">
                    <div className="w-full">
                            <CarouselGarage type={imagesUrls!.length > 3 ? "garageImagesPreview" : "garageImages"} multiple={imagesUrls!.length > 1}>
                                {imagesUrls?.map((img, index) => (
                                    <div key={index} className="w-[80%] cinco:w-[90%] h-[15rem] cinco:h-[15rem] bg-cover bg-center bg-no-repeat rounded-3xl" style={{ backgroundImage: `url(${img})` }}>
                                    </div>
                                ))}
                            </CarouselGarage>
                    </div>
                    <div className="pt-8 2xl:pr-8 rounded-lg">
                        <div className="mile100:flex justify-between pb-4">
                            <div className="flex justify-between mile100:flex-col gap-4">
                                <div className="flex flex-col">
                                    <h1 className="text-xl font-semibold">Estacionamento {garageId}</h1>
                                    {/* <div className="seis:flex gap-2">
                                        <p className="text-zinc-500">{covered ? "Coberto" : "Aberto"}</p>
                                        <div className="flex flex-row gap-2">
                                            <p><span className='inline-block w-2 h-2 mr-2 bg-blue-500 border border-black border-solid rounded-full'></span>Tipos: </p>
                                            <div className="seis:flex flex-row gap-2">
                                                {types.map((type, index) => (
                                                    <p key={index} className="underline">{type}{index < types.length - 1 ? "," : ""}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="flex justify-start">
                                    <div className="flex flex-col text-xl text-blue-600">
                                        <span>★★★★★</span>
                                        <span className="text-zinc-600 text-sm text-center">5 Opiniões</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid seis:grid-cols-2 pt-8 mile100:pt-0 mile100:flex mile100:flex-col">
                                <div className="flex pb-4 seis:justify-end seis:order-1 mile100:order-none">
                                    <div className="flex text-2xl font-semibold px-6">45€</div>
                                    <button className="bg-blue-500 text-white px-8 h-8 rounded-lg">Reservar</button>
                                </div>
                                <div className="gap-2 rounded-lg bg-yellow-300 flex px-2 py-1 max-w-fit max-h-8">
                                    <span>🗲</span>
                                    <p className="text-center text-black font-semibold">{displayChargers(numberChargers)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 seis:grid-cols-2 mile200:flex mile200:justify-between gap-6">
                            <div className="flex flex-col">
                                {activeServices!.length > 0 ? <div className="flex flex-row pt-10">

                                    <div className={`${activeServices.length && "border-r"} border-zinc-400 pr-2 flex items-center`}>
                                        <BsClockFill title="Horários" color="#bfc7ec" size={30} />
                                    </div>
                                    <div className={`grid ${activeServices.length < 4 ? "grid-flow-col auto-cols-max " : "grid-cols-4 "} gap-2 pl-2`}>
                                        {activeServices.map((item, index) => (
                                            <ServiceIcon key={index} serviceName={item} />
                                        ))}
                                    </div>
                                    
                                </div>: <p className="text-sm">Sem serviços associados</p>}
                                <div className="pt-4 pb-6">
                                    {activeServices.length > 0 && <hr className="w-full border-t border-zinc-400 max-w-[11.5rem]" />}
                                </div>
                                <div className="text-sm">
                                    <h2 className="font-semibold pb-4">Contactos</h2>
                                    <p>{contact}</p>
                                    <p>{email}</p>
                                </div>
                            </div>
                            <div className="rounded-lg">
                                <div className="flex items-center justify-between pb-4 w-1/2">
                                    <h2 className="text-lg font-semibold">Horário</h2>
                                </div>
                                {schedules!.length > 0 ? schedules.map((item, index) => (
                                    <div key={index} className="flex gap-2 cinco:gap-4 py-2">
                                        <p className="bg-transparent text-center w-28 rounded-full py-2 border border-solid border-black" >{item.day}</p>
                                        <p className="bg-transparent text-center w-16 rounded-full py-2 border border-solid border-black" >{item.open}</p>
                                        <p className="bg-transparent text-center w-16 rounded-full py-2 border border-solid border-black" >{item.close}</p>
                                    </div>
                                )) : <p className="text-sm">Sem horários associados</p>}
                            </div>
                            <div className="previewMap seis:col-span-2">
                                <h2 className="text-lg font-semibold pb-2">Local</h2>
                                <div id="map" />
                            </div>
                        </div>
                    </div>
                    <div className="pt-14 pb-10">
                        <div className="bg-zinc-200 rounded-2xl px-8 py-8">
                            <div className="flex gap-4">
                                <TbInfoCircleFilled title="Info" size={32} color="grey" />
                                <p className="text-md font-bold flex items-center">Cancelamento gratuito</p>
                            </div>
                            <div className="pt-6">
                                <p className="text-md font-bold">Porque é que tenho mais tempo?</p>
                                <p className="text-md font-bold">Cancelamento é grátis.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full 2xl:w-[30%]">
                    <PreviewCard />
                </div>
            </div>
        </Page>
    );

}

