

import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup , sendPasswordResetEmail } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_API_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_API_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_API_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_API_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_API_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_API_FIREBASE_MEASUREMENT_ID
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export async function signInWithGoogle() {
    try {
        const userCredential = await signInWithPopup(auth, provider);
        const idToken = await userCredential.user.getIdToken();

        return idToken;
    } catch (error) {
        console.error(error);
        return null;
    }
}



export async function sendResetPasswordEmail(email: string) {
    try {
        await sendPasswordResetEmail(auth, email);
        return "Password reset email sent successfully";
    } catch (error) {
        return (error as Error).message;
    }
}

export async function uploadToFirebase(files: File[], path: string, email: string) {
    try {
        const storage = getStorage(app);
        const uploadPromises = files.map(async (file) => {
            const newFileName = `${email}_${file.name}`; // Add email to file name to make it unique
            const storageRef = ref(storage, `${path}/${newFileName}`);

            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            return url;
        });

        const snapshots = await Promise.all(uploadPromises);

        return snapshots;
    } catch (error) {
        console.error(error);
        return null;
    }
}