import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Page from '../page';
import { IoAddCircle, IoClose, IoDocumentTextOutline, IoFilter } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../components/Modal';
import BookingInfo from './components/bookingInfo';
import axios from 'axios';
import { useUser } from '../../contexts/userInfo-context';
import { FiUpload } from 'react-icons/fi';
import * as XLSX from 'xlsx';
import Loading from '../../components/loading/loading';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp} from "react-icons/fa";
import { Booking } from '../../components/interfaces/interfaces';
import { isPhoneValid, validateEmail } from '../../components/ValidationUtils';
import MyPhoneInput from '../../components/phoneInput/PhoneInput';




const columnNames = [
    "date",
    "time",
    "clientName",
    "clientPhone",
    "clientEmail",
    "park",
    "row",
    "spot",
    "extraServices",
    "bookingPrice",
    "checkIn",
    "checkInTime",
    "checkOut",
    "checkOutTime",
];

export default function Clients() {
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const { garageId } = useParams<{ garageId: string }>();

    const [search, setSearch] = useState('');
    const [selectedBookings, setselectedBookings] = useState<Booking | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalImport, setShowModalImport] = useState(false);
    const [filter, setFilter] = useState('park');
    const [open, setOpen] = useState(false);
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [order, setOrder] = useState(false);


    const [date, setDate] = useState('');
    const [email, setEmail] = useState('');
    const [time, setTime] = useState('');
    const [parkName, setParkName] = useState('');
    const [row, setRow] = useState('');
    const [spot, setSpot] = useState('');
    const [extraServices, setExtraServices] = useState('');
    const [bookingPrice, setBookingPrice] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [checkInTime, setCheckInTime] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [checkOutTime, setCheckOutTime] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [options, setOptions] = useState<string[]>([]);
    
    useEffect(() => {
        setLoading(true)
        const activeGarages = sessionStorage.getItem('activeGarages');
        if (activeGarages) {
            const garages = JSON.parse(activeGarages);
            const options = garages.map((garage: { name: string; }) => garage.name);
            setOptions(options)
        } 
        setLoading(false)

    }, []);

    const selectGarageFilter = () => {
        if (!garageId) {
            return bookings;
        }
        return bookings.filter(booking => booking.park === garageId)
    }


    const [filteredBookings, setfilteredBookings] = useState(bookings);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!bookings) return;
        const result = bookings.filter(booking =>
            booking.park.toLowerCase().includes(search.toLowerCase()) ||
            booking.client.name.toLowerCase().includes(search.toLowerCase())
        );
        if (result.length === 0) {
            toast.error(`Não foram encontrados resultados para a pesquisa: ${search}`);
        }
        setfilteredBookings(result);
    };



    useEffect(() => {
        setfilteredBookings(selectGarageFilter);
    }, [garageId]);


    const handleClientsGetters = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/booking/all`, {
                headers: {
                    'Authorization': `${user!.accessToken}`
                }
            });
            sessionStorage.setItem('bookings', JSON.stringify(response.data.data))
            const bookingsGetted = response.data.data;
            setBookings(bookingsGetted);
            setfilteredBookings(bookingsGetted);

        } catch (error) {
            console.error('Error getting bookings:', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        const storedBookings = sessionStorage.getItem('bookings');
        if (storedBookings) {
            const bookingsSession = JSON.parse(storedBookings);
            setBookings(bookingsSession);
            setfilteredBookings(bookingsSession);
        } else {
            handleClientsGetters()
        };

    }, []);

    const handleExcelUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        e.target.value = "";
        let data: any;
        setShowModalImport(false);
        if (file) {
            try {
                data = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (evt) => {
                        try {
                            if (evt.target) {
                                const bstr = evt.target.result;
                                const wb = XLSX.read(bstr, { type: 'binary' });
                                const wsname = wb.SheetNames[0];
                                const ws = wb.Sheets[wsname];
                                data = XLSX.utils.sheet_to_json(ws);
                                if (data[0] && Object.keys(data[0]).length === 14) {
                                    data = data.map((item: any) => {
                                        const keys = Object.keys(item);
                                        const newItem: any = {};
                                        keys.forEach((key, index) => {
                                            const newKey = columnNames[index];
                                            newKey === 'extraServices' ?
                                                newItem[newKey] = String(item[key]).split(',') :
                                                (newKey === 'date' || newKey === 'checkIn' || newKey === 'checkOut') ?
                                                    newItem[newKey] = JSON.parse(item[key]) :
                                                    newItem[newKey] = item[key];
                                        });
                                        return newItem;
                                    });
                                    resolve(data);
                                } else {
                                    toast.error('Ficheiro inválido. Por favor siga o exemplo fornecido.');
                                    console.error('Invalid file format');
                                    reject('Invalid file format');
                                    setLoading(false);
                                    return;
                                }
                            }
                        } catch (error) {
                            console.error('An error occurred:', error);
                            toast.error('Ficheiro inválido. Por favor siga o exemplo fornecido.');
                            reject(error);
                            setLoading(false);
                            return;
                        }
                    };
                    reader.readAsBinaryString(file);
                });


                let park;
                for (let i = 0; i < data.length; i++) {
                    const parkName = data[i].park;
                    park = user!.garages.find((garage) => garage.name === parkName);
                    if (!park) {
                        toast.error(`Garagem não encontrada: ${parkName}`);
                        setLoading(false);
                        return;
                    }
                    data[i].garageId = park._id;

                    data[i].spot = data[i].spot.toString();
                    data[i].row = data[i].row.toString();

                    // Create a new client object
                    data[i].client = {
                        email: data[i].clientEmail,
                        name: data[i].clientName,
                        phone: data[i].clientPhone.toString(),
                    };

                    // delete the original attributes as they are no longer needed
                    delete data[i].clientEmail;
                    delete data[i].clientName;
                    delete data[i].clientPhone;
                }



                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/booking/create-list`,
                    data,
                    {
                        headers: {
                            Authorization: `${user!.accessToken}`
                        }
                    });

                if (response.data.condition === 'partial success') {
                    toast.error('Erro ao importar algumas reservas. Verifique se os dados estão corretos.');
                } else {
                    const newBookings = [...bookings, ...data];
                    setBookings(newBookings);
                    sessionStorage.setItem('bookings', JSON.stringify(newBookings));
                    toast.success('Reservas importadas com sucesso.');
                }




                setLoading(false);
            } catch (error) {
                console.error('Error creating bookings:', error);
                setLoading(false);
                toast.error('Ocorreu um erro ao criar as reservas. Tente mais tarde');
            } finally {
                setLoading(false);
            }
        }

    }

    const addOne = () => {

        if (!date || !email || !name || !phone || !time || !parkName || !row || !spot || !extraServices || !bookingPrice || !checkIn || !checkInTime || !checkOut || !checkOutTime) {
            toast.error('Preencha todos os campos.');
            return;
        }

        if (isNaN(Number(bookingPrice)) || isNaN(Number(phone))) {
            toast.error('O preço da reserva e o contact devem ser um número.');
            return;
        }

        if (Number(bookingPrice) <= 0) {
            toast.error('O preço da reserva tem de ser superior a 0.');
            return;
        }
        if (!validateEmail(email)) {
            toast.error('Email inválido.');
            return;
        }

        if (!isPhoneValid(phone)) {
            toast.error('Contacto inválido.');
            return;
        }
        
        setLoading(true);
        const park = user!.garages.find((garage) => garage.name === parkName);
        if (!park) {
            toast.error(`Garagem não encontrada: ${parkName}`);
            setLoading(false);
            return;
        }

        const newBooking: Booking = {
            date: date,
            time: time,
            client: {
                name: name,
                phone: phone,
                email: email,
            },
            park: parkName,
            row: row,
            spot: spot,
            extraServices: extraServices.split(','),
            bookingPrice: Number(bookingPrice),
            checkIn: checkIn,
            checkInTime: checkInTime,
            checkOut: checkOut,
            checkOutTime: checkOutTime,
            garageId: park._id
        }

        try {
            axios.post(`${process.env.REACT_APP_API_URL}/api/v1/booking/create`, newBooking,
                {
                    headers: {
                        Authorization: `${user!.accessToken}`
                    }
                }
            ).then((response) => {
                const newBookings = [...bookings, newBooking];
                setBookings(newBookings);
                sessionStorage.setItem('bookings', JSON.stringify(newBookings));
                toast.success('Reserva criada com sucesso.');
                setLoading(false);
                setDate('');
                setEmail('');
                setTime('');
                setParkName('');
                setRow('');
                setSpot('');
                setExtraServices('');
                setBookingPrice('');
                setCheckIn('');
                setCheckInTime('');
                setCheckOut('');
                setCheckOutTime('');
                setName('');
                setPhone('');
                
            }).catch((error) => {
                console.error('Error creating booking:', error);
                toast.error('Ocorreu um erro ao criar a reserva. Tente mais tarde.');
                setLoading(false);
            });

            //update bookings
        }
        catch (error) {
            console.error('Error creating booking:', error);
            toast.error('An error occurred. Please try again later');
            setLoading(false);
            return;
        }

    }

    useEffect(() => {
        setfilteredBookings(selectGarageFilter);
    }, [bookings]);

    function normalizeDate(dateString: string) {
        return dateString.replace(/"/g, '');
    }

    function sortFilteredBookings(filteredBookings: any[]) {
        return filteredBookings.sort((a, b) => {
            if (a[filter] < b[filter]) {
                return order ? -1 : 1;
            }
            if (a[filter] > b[filter]) {
                return order ? 1 : -1;
            }
            return 0;
        });
    }

    const downloadExcel = () => {
        const cleanedBookings = filteredBookings.map(({ _id, userId, garageId, client, ...rest }) => ({
            ...rest,
            clientName: client.name, 
            clientEmail: client.email, 
            clientPhone: client.phone, 
        }));
        const worksheet = XLSX.utils.json_to_sheet(cleanedBookings);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `Bookings-${!garageId ? "All" : garageId}`);
        XLSX.writeFile(workbook,`Bookings-${!garageId ? "All" : garageId}.xlsx`);
      };

    return (
        <Page >
            <Toaster />
            <Header title='Clients' value={!garageId ? "All" : garageId} options={options.concat("All")} />
            {/* 0 to simbolize all */}
            <div className="py-12 seis:px-[4vw] px-[8vw] gap-4 min-h-full">
                <p className='p-8 text-xl font-bold'>
                    Bookings
                </p>
                <div className="quatro:p-4 quatro:bg-white rounded-xl">
                    <div className="grid grid-cols-1 mile400:grid-cols-2 justify-between gap-4 py-4 font-bold">
                        <div className='flex gap-3'>
                            <button onClick={() => setShowModalImport(true)} className="px-4 py-3 bg-blue-100 rounded-full text-black border border-solid border-blue-500">Importar</button>
                            <button onClick={downloadExcel} className="px-4 py-3 bg-blue-100 rounded-full text-black border border-solid border-blue-500">Exportar</button>
                        </div>
                        {bookings && <div className="flex gap-4 relative">
                            <div className='flex items-center cursor-pointer' onClick={() => setOrder(!order)}>
                                {order ? <FaLongArrowAltDown size={32}/> : <FaLongArrowAltUp size={32}/>}
                            </div>
                            <div onClick={() => setOpen(!open)} className="cursor-pointer bg-white quatro:bg-transparent flex gap-2 bg-transparent border border-zinc-400 text-zinc-400 px-4 py-2 rounded-xl">
                                <IoFilter size={32} title='Order by' />
                            </div>
                            {open && <div className={`flex gap-1 flex-col absolute top-14 translate-x-[20%] bg-white rounded-xl transition-opacity ease-in-out duration-200 border border-solid border-zinc-400 p-2`}>
                                <button onClick={() => {if(open){ setFilter("checkOut")}; setOpen(false)}} className={`${filter === "checkOut" ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>Check Out</button>
                                <button onClick={() => {if(open){ setFilter("checkIn")}; setOpen(false)}} className={`${filter === "checkIn" ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>Check In</button>
                                <button onClick={() => {if(open){ setFilter("bookingPrice")}; setOpen(false)}} className={`${filter === "bookingPrice" ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>Preço</button>
                                <button onClick={() => {if(open){ setFilter("park")}; setOpen(false)}} className={`${filter === "park" ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>Parque</button>
                            </div>}

                            <form action="/search" className="w-full" onSubmit={handleSearchSubmit}>
                                <div className="relative seis:w-80 seis:min-w-80 mile400:w-full">
                                    <input type="text" name="search" placeholder='Nome do parque ou cliente' className="bg-white quatro:bg-transparent border border-solid rounded-xl border-zinc-300 w-full h-12 p-4 pl-12 bg-transparent shadow dark:text-gray-800 placeholder-transparent seis:placeholder-zinc-400" onChange={handleSearchChange} value={search} />
                                    <button type="submit">
                                        <svg className="text-black h-5 w-5 absolute top-3.5 left-3 fill-current"
                                            xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                                            x="0px" y="0px" viewBox="0 0 56.966 56.966"
                                            xmlSpace="preserve">
                                            <path
                                                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>}
                    </div>
                    <div className="bg-white shadow rounded-lg overflow-x-auto flex">
                        <table className="text-zinc-500 w-full">
                            <thead>
                                <tr className='text-left bg-zinc-100 '>
                                    <th className="p-4 crounded-tl-lg">Data</th>
                                    <th className="p-4 ">Hora ou Período</th>
                                    <th className="p-4 ">Nome do Cliente</th>
                                    <th className="p-4 ">Contacto do Cliente</th>
                                    <th className="p-4 ">Email do Cliente</th>
                                    <th className="p-4 ">Nome do Parque</th>
                                    <th className="p-4 ">Linha</th>
                                    <th className="p-4 ">Lugar</th>
                                    <th className="p-4 ">Serviços Extra</th>
                                    <th className="p-4 ">Preço</th>
                                    <th className="p-4 ">Data de check in</th>
                                    <th className="p-4 ">Hora do check in</th>
                                    <th className="p-4 ">Data de check out</th>
                                    <th className="p-4 rounded-tr-lg">Hora do check out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortFilteredBookings(filteredBookings).map((booking, index) => (
                                    <tr key={index} className='cursor-pointer' onClick={() => { setselectedBookings(booking); setShowModal(true) }}>
                                        <td className="text-black p-4">
                                            {normalizeDate(booking.date)}
                                        </td>
                                        <td className='p-4'>
                                            {booking.time}
                                        </td>
                                        <td className='p-4'>
                                            {booking.client.name}
                                        </td>
                                        <td className='p-4'>
                                            {booking.client.phone}
                                        </td>
                                        <td className='p-4'>
                                            {booking.client.email}
                                        </td>
                                        <td className='p-4'>
                                            {booking.park}
                                        </td>
                                        <td className='p-4'>
                                            {booking.row}
                                        </td>
                                        <td className='p-4'>
                                            {booking.spot}
                                        </td>
                                        <td className='p-4'>
                                            {booking.extraServices ? booking.extraServices.join(", ") : "Nenhum"}
                                        </td>
                                        <td className='p-4'>
                                            {booking.bookingPrice}
                                        </td>
                                        <td className='p-4'>
                                            {normalizeDate(booking.checkIn)}
                                        </td>
                                        <td className='p-4'>
                                            {booking.checkInTime}
                                        </td>
                                        <td className='p-4'>
                                            {normalizeDate(booking.checkOut)}
                                        </td>
                                        <td className='p-4'>
                                            {booking.checkOutTime}
                                        </td>
                                    </tr>

                                ))
                                }
                                <tr key={-1}>
                                    <td className='p-4'>
                                        <input type="text" value={date} placeholder="Data" className="h-8" onChange={(e) => setDate(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={time} placeholder="Hora ou Período" className="h-8" onChange={(e) => setTime(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={name} placeholder="Nome do Cliente" className="h-8" onChange={(e) => setName(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <MyPhoneInput contact={phone} setContact={setPhone} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={email} placeholder="Email do Cliente" className="h-8" onChange={(e) => setEmail(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={parkName} placeholder="Nome do Parque" className="h-8" onChange={(e) => setParkName(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={row} placeholder="Linha" className="h-8" onChange={(e) => setRow(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={spot} placeholder="Lugar" className="h-8" onChange={(e) => setSpot(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={extraServices} placeholder="Serviços Extra" className="h-8" onChange={(e) => setExtraServices(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={bookingPrice} placeholder="Preço" className="h-8" onChange={(e) => setBookingPrice(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={checkIn} placeholder="Data de check in" className="h-8" onChange={(e) => setCheckIn(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={checkInTime} placeholder="Hora do check in" className="h-8" onChange={(e) => setCheckInTime(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={checkOut} placeholder="Data de check out" className="h-8" onChange={(e) => setCheckOut(e.target.value)} />
                                    </td>
                                    <td className='p-4'>
                                        <input type="text" value={checkOutTime} placeholder="Hora do check out" className="h-8" onChange={(e) => setCheckOutTime(e.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='flex items-end pb-4'>
                            <IoAddCircle className="cursor-pointer hover:scale-110 transition-transform duration-200" size={32} title="Adicionar" onClick={addOne} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={showModal}>
                <IoClose onClick={() => setShowModal(false)} title="Fechar" className="absolute top-2 right-4 cursor-pointer" size={32} />
                <div className="rounded-lg px-8 py-14 seis:p-14 w-full">
                    <BookingInfo booking={selectedBookings} />
                </div>
            </Modal>
            <Modal isOpen={showModalImport}>
                <IoClose onClick={() => setShowModalImport(false)} title="Fechar" className="absolute top-2 right-4 cursor-pointer" size={32} />
                <div className="rounded-lg px-8 py-14 seis:p-14 max-w-3xl">
                    <h2 className='text-xl font-bold pb-8'>Importar ficheiro Excel</h2>
                    <p>O ficheiro deve conter as seguintes colunas, respeitando a ordem:</p>
                    <p className='text-xs text-zinc-500'>Deverá preencher todos os campos tal como no exemplo. Para as datas deverá colocar entre aspas assim como no exemplo</p>
                    <div className="bg-white shadow rounded-lg overflow-x-auto w-full">
                        <table className="text-zinc-500 w-full">
                            <thead>
                                <tr className='text-left bg-zinc-100 '>
                                    <th className="p-4 border">Data</th>
                                    <th className="p-4 border">Hora ou Período</th>
                                    <th className="p-4 border">Nome do Cliente</th>
                                    <th className="p-4 border">Contacto do Cliente</th>
                                    <th className="p-4 border">Email do Cliente</th>
                                    <th className="p-4 border">Nome do Parque</th>
                                    <th className="p-4 border">Linha</th>
                                    <th className="p-4 border">Lugar</th>
                                    <th className="p-4 border">Serviços Extra</th>
                                    <th className="p-4 border">Preço</th>
                                    <th className="p-4 border">Data de check in</th>
                                    <th className="p-4 border">Hora do check in</th>
                                    <th className="p-4 border">Data de check out</th>
                                    <th className="p-4 border">Hora do check out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-zinc-400'>
                                    <th className="p-4 border">Data</th>
                                    <th className="p-4 border">Hora ou Período</th>
                                    <th className="p-4 border">Nome do Cliente</th>
                                    <th className="p-4 border">Contacto do Cliente</th>
                                    <th className="p-4 border">Email do Cliente</th>
                                    <th className="p-4 border">Nome do Parque</th>
                                    <th className="p-4 border">Linha</th>
                                    <th className="p-4 border">Lugar</th>
                                    <th className="p-4 border">Serviços Extra</th>
                                    <th className="p-4 border">Preço</th>
                                    <th className="p-4 border">Data de check in</th>
                                    <th className="p-4 border">Hora do check in</th>
                                    <th className="p-4 border">Data de check out</th>
                                    <th className="p-4 border">Hora do check out</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='flex flex-row gap-8 pt-8 justify-around'>
                        <div className='flex flex-col gap-2'>
                            <div className=' cursor-pointer max-w-48 hover:scale-105 h-full transition-transform duration-200 font-bold bg-[var(--primary)] px-3 py-1 rounded-xl flex items-center'>
                                <a className="text-white text-sm " href="/example.xlsx" download>TRANSFERIR EXEMPLO</a>
                            </div>
                        </div>
                        <div className="relative w-32">
                            <label title="Click to upload" htmlFor="ImportExcel" className="relative z-10 cursor-pointer hover:scale-105 transition-transform duration-200 flex bg-[var(--primary)] px-4 py-2 rounded-xl gap-3 min-w-40">
                                <label htmlFor="ImportExcel" className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer">
                                    IMPORTAR
                                    <FiUpload color="white" size={20} />
                                    <IoDocumentTextOutline color="white" size={20} />
                                </label>
                            </label>
                            <input hidden type="file" name="ImportExcel" id="ImportExcel"
                                className="w-full"
                                accept=".xlsx"
                                onChange={(e) => {
                                    setLoading(true);
                                    handleExcelUpload(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>
    );
}

