import { TbInfoCircleFilled } from "react-icons/tb";

const PreviewCard = () => {


    return (
        <div className=" rounded-lg shadow-2xl p-4">
            <h2 className="text-lg font-semibold text-zinc-800 pb-2">Detalhes da reserva</h2>
            <div className="border border-zinc-400 rounded-2xl px-2 py-4">
                <div className="flex justify-between items-center pb-2">
                    <div>
                        <h3 className="text-zinc-700 font-bold">Passe ilimitado</h3>
                        <p className="text-zinc-500">ENTRADA E SAÍDA LIMITADAS</p>
                    </div>
                    <div className="text-right">
                        <span className="text-3xl font-bold text-red-500">31,50 €</span>
                        <p className="text-zinc-400 text-sm">Este produto é de 1 dia</p>
                    </div>
                </div>
                <hr className="w-full border-t border-zinc-400" />
                <div className="pt-4">
                    <div className="flex justify-between pb-2">
                        <div>
                            <p className="text-zinc-600">Entrada</p>
                            <p className="font-semibold text-zinc-800">qui, 21 mar. 10:00</p>
                        </div>
                        <div>
                            <p className="text-zinc-600">Saída</p>
                            <p className="font-semibold text-zinc-800">sex, 22 mar. 10:00</p>
                        </div>
                    </div>
                    <hr className="w-full border-t border-zinc-400" />
                    <div className="pt-4 grid grid-cols-1 seis:grid-cols-2 gap-2">
                        <div>
                            <p className="text-zinc-600">Veículo</p>
                            <p className="font-semibold text-zinc-800">Turismo</p>
                        </div>
                        <div className=" text-start">
                            <p className="text-zinc-600">Altura máxima do parque de estacionamento</p>
                            <p className="font-semibold text-zinc-800">2.1m</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 p-3 px-[10%]">
                <p className="text-zinc-800">Este produto é de 1 dia</p>
                <button className="text-blue-700 text-start">Porque é que tenho mais tempo?</button>
            </div>
            <p className="py-2 px-2 bg-zinc-300 rounded-2xl text-center">
                Este é o melhor produto que encontrámos para a sua pesquisa!
            </p>
            <p className="text-sm text-zinc-500 py-4">
                O produto 2 horas que procurou neste parque de estacionamento tem o mesmo preço que o
                produto 1 dia que oferecemos.
            </p>
            <div className="flex items-center py-2 px-2 bg-zinc-300 rounded-2xl justify-center">
                <TbInfoCircleFilled title="Info" size={24} color="black" />
                <p className="pl-2">Cancelamento gratuito</p>
            </div>
            <div className="pt-4">
                <button className="w-full bg-red-500 text-white p-3 rounded-2xl">Continuar</button>
            </div>
        </div>
    );
}


export default PreviewCard;