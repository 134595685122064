
import { BrowserRouter,  Route,  Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import NotFoundPage from './pages/NotFound';
import Clients from './pages/clients/Clients';
import Settings from './pages/settings/Settings';
import Billing from './pages/billing/Billing';
import Statistics from './pages/statistics/Statistics';
import { WindowWidthProvider } from './contexts/width-context';
import { UserProvider } from './contexts/userInfo-context';
import HomeMap from './pages/map/HomeMap';
import CreateGarage from './pages/createGarage/CreateGarage';
import Garage from './pages/garages/garage/Garage';
import Preview from './pages/garages/previewgarage/Preview';
import { GarageProvider } from './contexts/selectedGarage';
import ClientsChat from './pages/clients/ClientsChat';
import { PendingChangesProvider } from './contexts/pendingChanges-context';

const App = () => {

	return (
		<GarageProvider>
			<WindowWidthProvider>
				<UserProvider>
					<PendingChangesProvider>
						<BrowserRouter>
							<Routes>
								<Route index element={<Login />} />
								<Route path="/home" element={<Home />} />
								<Route path="/home/map" element={<HomeMap />} />
								<Route path="/home/create" element={<CreateGarage />} />
								<Route path="/home/garage/:garageId" element={<Garage />} />
								<Route path="/home/garage/:garageId/preview" element={<Preview />} />
								<Route path="/clients/:garageId?" element={<Clients />} />
								<Route path="/clients/:garageId/chat" element={<ClientsChat />} />
								<Route path="/statistics" element={<Statistics />} />
								<Route path="/billing/:garageId?" element={<Billing />} />
								<Route path="/settings" element={<Settings />} />
								<Route path="*" element={<NotFoundPage />} />
							</Routes>
						</BrowserRouter>
					</PendingChangesProvider>
				</UserProvider>
			</WindowWidthProvider>
		</GarageProvider>
	);
};

export default App;
