import { Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useUser } from "../contexts/userInfo-context";
import { useWindowWidth } from "../contexts/width-context";

const Page = ({ children }: { children: any }) => {
	const {user} = useUser();
	const windowWidth = useWindowWidth();

	if (!user) {
		return <Navigate to='/' />;
	}
	
	return (
		<div className='flex'>
			<Navbar windowWidth={windowWidth}/>
			<div className={windowWidth >= 900 ? 'w-full oito:pl-[17rem] text-[var(--black)]': 'w-full mt-[6rem]'}>
				{children}
			</div>
		</div>
	);
};

export default Page;