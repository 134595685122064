import axios from 'axios';
import qs from 'qs';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { User } from '../components/interfaces/interfaces';



const UserContext = createContext({
  user: null as User | null,
  setUser: (value: User | null) => { },
});

async function exchangeRefreshTokenForIdToken(refreshToken: string) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  };

  const config = {
    method: 'post',
    url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_FIREBASE_KEY}`,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : qs.stringify(data)
  };

  try {
    const response = await axios(config);
    return {accessToken: response.data.id_token, refreshToken: response.data.refresh_token};
  } catch (error) {
    console.error(error);
  }
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = sessionStorage.getItem('user');
    const storedUserLocal = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : storedUserLocal ? JSON.parse(storedUserLocal) : null;
  });

  useEffect(() => {
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
      let activeGarages = [];
      for (const garage of user.garages) {
        if(garage.active || garage.status === 'active')
          activeGarages.push(garage);
      }
      sessionStorage.setItem('activeGarages', JSON.stringify(activeGarages));
      // if (localStorage.getItem('remember') === 'true') {
      //   localStorage.setItem('user', JSON.stringify(user));
      // }
    }
  }, [user]);

  useEffect(() => {
    const checkExpiration = async (retryCount = 0) => {
      try {
        if (user) {
          const newTokens = await exchangeRefreshTokenForIdToken(user.refreshToken);
          setUser({
            ...user,
            accessToken: newTokens?.accessToken,
            refreshToken: newTokens?.refreshToken,
          });
        }
      } catch (error) {
        if (retryCount < 5) { // Maximum 5 retries
          console.error('Failed to refresh token, retrying...', error);
          setTimeout(() => checkExpiration(retryCount + 1), 2000); // Retry after 2 seconds
        } else {
          console.error('Failed to refresh token after 3 attempts', error);
        }
      }
    };

    // Run the check immediately
    checkExpiration();

    // Then run it every 45 minutes
    const intervalId = setInterval(() => checkExpiration(), 45 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);


  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};