import Header from '../../components/Header';
import Page from '../page';
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { useUser } from '../../contexts/userInfo-context';
import { sendResetPasswordEmail, uploadToFirebase } from '../../firebase/firebase';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../components/loading/loading';
import { UserSettings } from '../../components/interfaces/interfaces';
import MyPhoneInput from '../../components/phoneInput/PhoneInput';
import { isPhoneValid, validateEmail } from '../../components/ValidationUtils';



export default function Settings() {
    const { user } = useUser();
    let letter = user!.firstName[0].toUpperCase();
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [firstName, setFirstName] = useState<string>(user!.firstName);
    const [lastName, setLastName] = useState<string>(user!.lastName);
    const [email, setEmail] = useState<string>(user!.email);
    const [phoneNumber, setPhoneNumber] = useState<string>(user!.phoneNumber || '');
    const [loading, setLoading] = useState(false);
    const defaultPlaceholderImage = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-30 0 100 100'%3E%3Ctext y='0.9em' font-size='90' fill='white'%3E${letter}%3C/text%3E%3C/svg%3E`;
    const activeGarages = sessionStorage.getItem('activeGarages') ? JSON.parse(sessionStorage.getItem('activeGarages')!) : [];



    const handleSave = async () => {
        if (isNaN(Number(phoneNumber))) {
            toast.error('O contacto tem de ser um valor numérico.');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Email inválido.');
            return;
        }

        if (!isPhoneValid(phoneNumber)) {
            toast.error('Contacto inválido.');
            return;
        }

        setLoading(true);



        const uploadedImages = selectedImage ? await uploadToFirebase([selectedImage], 'users', user!.email) : null;

        if (!uploadedImages && selectedImage) {
            toast.error('Erro ao fazer upload dos ficheiros. Tente novamente.');
            setLoading(false);
            return;
        }

        let data: UserSettings = {
            firstName,
            lastName,
            email,
            phoneNumber,
        };

        if (uploadedImages) {
            data.image = uploadedImages[0];
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/user/update/${user!._id}`, data, {
                headers: {
                    'Authorization': user!.accessToken
                }
            });
            if (response.data.condition === 'success') {
                user!.firstName = firstName;
                user!.lastName = lastName;
                if (uploadedImages) {
                    user!.image = uploadedImages[0];
                }
                toast.success('User atualizado com sucesso.');
            }

        } catch (error) {
            toast.error('Erro ao fazer o update. Tente novamente.');
            console.error('Error updating user:', error);
        }
        setLoading(false);
    }
    const changePass = async () => {
        await sendResetPasswordEmail(user!.email);
        toast.success(`Email enviado para ${user!.email} com instruções para restituir a palavra-passe.`);
    }

    return (
        <Page>
            <Toaster />
            <Header title='Settings' />
            <div className='relative flex w-full py-12 seis:px-[4vw] px-[8vw] mil:flex-row flex-col gap-10 h-full'>
                <button onClick={handleSave} className="absolute bottom-20 flex items-center gap-2 px-4 py-2 text-white bg-[var(--primary)] rounded-xl hover:bg-[var(--primary-dark]">Guardar</button>
                <div className='flex w-full'>
                    <div className='flex flex-col w-full gap-10'>
                        <div className='flex justify-between'>
                            <p className="flex flex-col gap-2 text-2xl text-[var(--primary)] font-bold ">Profile</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className="text-2xl font-bold text-black">Nome</label>
                            <input
                                type="text"
                                placeholder='Nome'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className='w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] outline-[var(--primary)]'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className="text-2xl font-bold text-black">Apelido</label>
                            <input
                                type="text"
                                placeholder='Nome'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className='w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] outline-[var(--primary)]'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className="text-2xl font-bold text-black">Email</label>
                            <input
                                type="text"
                                placeholder='Nome'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] outline-[var(--primary)]'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className="text-2xl font-bold text-black">Contacto</label>
                            <div className="w-full text-[var(--primary)] placeholder-[var(--primary)] text-lg py-1 px-6 rounded-full mt-2 font-medium bg-white">
                                <MyPhoneInput contact={phoneNumber} setContact={setPhoneNumber} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex w-full'>
                    <div className='w-full'>
                        <div className="relative w-[12rem]">
                            <img
                                className=" w-[12rem] aspect-square border-2 border-black rounded-full bg-[var(--primary)]"
                                src={user!.image || defaultPlaceholderImage}
                                alt="profileImage"
                                onError={(e) => {
                                    e.currentTarget.src = defaultPlaceholderImage;
                                }}
                            />
                            <div className='absolute transition-transform duration-300 bg-white border-4 border-white border-solid rounded-full cursor-pointer left-40 bottom-4 hover:scale-125'>
                                <div className="relative">
                                    <label title="Click to upload" htmlFor="buttonImages" >
                                        <label htmlFor="buttonImages" className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer">
                                            <MdOutlineFileUpload color='blue' size={"32px"} />
                                        </label>
                                    </label>
                                    <input
                                        hidden
                                        type="file"
                                        name="buttonImages"
                                        id="buttonImages"
                                        className="w-full"
                                        accept=".png, .jpg"
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                setSelectedImage(e.target.files[0]);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {activeGarages && <div className='py-10 flex flex-col gap-4'>
                            <label className="text-2xl font-bold text-black">Garagens Ativas</label>
                            {activeGarages.map((garage: { _id: Key | null | undefined; name: string ; address: { address: string }; }) => {
                                return (
                                    <div key={garage?._id} className='flex gap-4'>
                                        <div className='w-[30%]'>
                                            <p className="text-xl font-semibold">{garage?.name}</p>
                                        </div>
                                        <div className='w-[70%] '>
                                            <p className="text-xl font-semibold">{garage?.address?.address}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                        <div className='flex'>
                            <div className="flex items-center justify-center" >
                                <IoIosLock size={"32px"} />
                                <p onClick={changePass} className="flex items-center justify-center pl-4 font-semibold text-left text-blue-400 transition-transform duration-300 cursor-pointer select-none hover:scale-110">Restituir Palavra-Passe</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loading isOpen={loading} />
        </Page>
    );
}
