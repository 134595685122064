import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Page from "../page";
import { GrMapLocation } from "react-icons/gr";
import { IoAddCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import GarageCard from "../../components/GarageCard";
import { useUser } from "../../contexts/userInfo-context";
import toast, { Toaster } from "react-hot-toast";
import { Garage } from "../../components/interfaces/interfaces";

export default function Home() {
    const {user} = useUser();
    const [garageStatus, setGarageStatus] = useState(true);
    const [search, setSearch] = useState('');
    const [filteredGarages, setFilteredGarages] = useState<Garage[]>([]);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        const activeGarages = sessionStorage.getItem('activeGarages');
        if (activeGarages) {
            setFilteredGarages(JSON.parse(activeGarages));
        }
        
    }, []);


    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!user!.garages) return;
        const result = user!.garages.filter(garage =>
            garage.active === garageStatus &&
            (garage.name.toLowerCase().includes(search.toLowerCase()) ||
                garage.address.address.toLowerCase().includes(search.toLowerCase()))
        );
        if (result.length === 0) {
            toast.error(`Não foram encontrados resultados para a pesquisa: ${search}`);
        }
        setFilteredGarages(result);
    };

    const handleStatusChange = (status: boolean) => {
        
        setGarageStatus(status);
        if(!user!.garages) return;
        const result = user!.garages.filter(garage => (garage.active === undefined ? false : garage.active) === status);
        setFilteredGarages(result);
    }


    return (
        <Page >
            <Toaster />
            <Header title='Garagens' />
            <div className='w-full min-h-full py-12 seis:px-[4vw] px-[8vw] flex flex-col gap-8'>
                <div className="flex flex-col items-center justify-between w-full gap-6 quatro:flex-row">
                    <p className="text-2xl font-bold text-[var(--primary)] w-full">As minhas garagens</p>
                    <Link className="flex justify-end w-full" to="map">
                        <p className="flex items-center text-[var(--primary)] text-2xl font-bold hover:underline underline-offset-2 pr-4">Mapa</p>
                        <GrMapLocation size={40} />
                    </Link>
                </div>
                <div className="flex flex-col gap-6">
                    <div className="flex justify-between bg-white rounded-[40px] p-5 gap-4">
                        <form action="/search" className="w-full max-w-lg seis:w-1/2" onSubmit={handleSearchSubmit}>
                            <div className="relative">
                                <input type="text" name="search" className="w-full h-12 p-4 pr-12 bg-gray-200 rounded-full shadow dark:text-gray-800" onChange={handleSearchChange} value={search} />
                                <button type="submit">
                                    <svg className="text-black h-5 w-5 absolute top-3.5 right-3 fill-current"
                                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                                        x="0px" y="0px" viewBox="0 0 56.966 56.966"
                                        xmlSpace="preserve">
                                        <path
                                            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                        <Link to="create" className="flex items-center justify-end gap-4 transition-transform duration-200 hover:scale-105 ">
                            <p className="items-center hidden text-xl font-bold seis:flex">Nova garagem</p>
                            <IoAddCircle size={40} color="var(--primary)" />
                        </Link>
                    </div>
                    <div className="flex justify-center w-full">
                        <div className="w-full p-4 bg-white rounded-full mil:w-[30rem]">
                            <div className="relative flex justify-between gap-4 font-bold text-[.9rem] cinco:text-[1.2rem]">
                                <div id="this" className={`top-1/2 translate-y-[-50%] w-1/2 z-0 absolute text-xl font-bold rounded-full h-12 bg-[var(--primary)] transition-all duration-500 ${garageStatus ? 'left-0' : 'left-1/2'}`}></div>
                                <button
                                    onClick={() => handleStatusChange(true)}
                                    className={`z-0 relative w-full px-4 py-2 transition-colors duration-500 ${garageStatus ? 'text-white' : 'text-black'}`}
                                >
                                    Ativas
                                </button>
                                <button
                                    onClick={() => handleStatusChange(false)}
                                    className={`z-0 relative w-full px-4 py-2 transition-colors duration-500 ${!garageStatus ? 'text-white' : 'text-black'}`}
                                >
                                    Em aprovação
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={`garages grid grid-cols-1 mile200:grid-cols-2 mile400:grid-cols-3 gap-10`}>
                    {filteredGarages.map((garage, index) => (
                        <GarageCard garage={garage} key={index} />
                    ))}
                </div>
            </div>
        </Page>
    );
}

