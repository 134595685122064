import Header from "../../../components/Header";
import Page from "../../page";
import { useNavigate, useParams } from "react-router-dom";
import { DragEvent, SetStateAction, useEffect, useRef, useState } from "react";
import AddCharger from "../../../components/AddCharger";
//carousel
import CarouselGarage from "../components/Carousel";
//modal
import Modal from "../../../components/Modal";
import Help from "../components/Help";
//toaster
import toast, { Toaster } from 'react-hot-toast';
//map
import "./garage.styles.scss";
import { Geocoder } from 'leaflet-control-geocoder';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import marker from "../../../content/images/icons/parking.png"
import pin from "../../../content/images/icons/pin.png"
import L, { LatLngExpression } from "leaflet";
import "leaflet-draw";
//icons
import { IoAddCircle, IoClose, IoHelpCircleOutline, IoArrowBack } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import ServiceIcon from "../components/ServiceIconMapper";
import axios from "axios";
import { useUser } from "../../../contexts/userInfo-context";
import Loading from "../../../components/loading/loading";
import { usePendingChanges } from "../../../contexts/pendingChanges-context";
import { uploadToFirebase } from "../../../firebase/firebase";
import VehicleOptionsComponent from "../../../components/VehicleOption";
import { Charger, Garage_get, Price, Schedule } from "../../../components/interfaces/interfaces";
import MyPhoneInput from "../../../components/phoneInput/PhoneInput";
import { isPhoneValid, validateEmail } from "../../../components/ValidationUtils";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";




export default function GaragePage() {
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [pendingChanges, setPendingChanges] = usePendingChanges();
    const activeGarages = sessionStorage.getItem('activeGarages') ? JSON.parse(sessionStorage.getItem('activeGarages')!) : [];
    const { garageId } = useParams<{ garageId: string }>();
    const daysOfTheWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
    const services = ['Digital Access', 'Pintura', 'Rent', 'Lavagens', 'IPO', 'Revisão', 'Deficientes', 'Chave Física', '24h aberto', 'Câmeras'];
    /*
    Data for the garage, used for testing purposes, will be replaced by the data from the backend
    */
    const [chargers, setChargers] = useState<Charger[]>([]);
    const [id, setId] = useState("");
    // const chargerTypes = useChargerTypes();
    const [popup, setPopup] = useState(false);
    //handle source for drag and drop, to be able to change from services to active services and vice versa
    const [draggingFrom, setDraggingFrom] = useState<string | null>(null);
    //services to display in the modal with drag and drop
    const [servicesModal, setServicesModal] = useState<string[]>(services);
    const [activeServicesModal, setActiveServicesModal] = useState<string[]>([]);
    //actual services active, after adding/removing active services and saved alterations will be noticed
    const [activeServices, setActiveServices] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [imagesUrls, setImagesUrls] = useState<string[]>([]);
    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [prices, setPrices] = useState<Price[]>([]);
    const [price, setPrice] = useState(0);
    const [deletedPrice, setDeletedPrice] = useState(false);
    const [startTime, setStartTime] = useState("09:00");
    const [endTime, setEndTime] = useState("10:00");
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [deletedSchedule, setDeletedSchedule] = useState(false);
    const [isOpenServices, setIsOpenServices] = useState(false);
    const [isOpenPrices, setIsOpenPrices] = useState(false);
    const [isOpenSchedules, setIsOpenSchedules] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [address, setAddress] = useState("Portugal")
    const [addressCoords, setAddressCoords] = useState<[number, number]>([35, 35]);
    const [auxAddressCoords, setAuxAddressCoords] = useState<[number, number]>([35, 35]);
    const mapRef = useRef<L.Map | L.LayerGroup<any> | null>(null);
    //icon for the marker on the map(multipark marker icon)
    const icon = L.icon({
        iconUrl: marker,
        iconSize: [70, 70],
    });
    const markerMap = useRef<L.Marker | null>(null);
    const auxMarkerMap = useRef<L.Marker | null>(null);


    const [vehicleType, setVehicleType] = useState("selecione");
    const [isOpenVehicleType, setOpenVehicleType] = useState(false);
    const [isOpenDay, setOpenDay] = useState(false);
    const [dayOfTheWeek, setDayOfTheWeek] = useState("Dia da Semana");

    const vehicleOptions = VehicleOptionsComponent().vehicleOptions;



    const handleEditPrices = () => {
        const oldPrices = Array.isArray(prices) ? prices : [];

        if (!deletedPrice) {
            if (vehicleType === "selecione") {
                toast.error("Tipo não preenchido.")
                return;
            }
            if (!price) {
                toast.error("Preço não preenchido. Preencha o campo com um valor numérico.")
                return;
            }
            // Check for repeated types
            if (oldPrices.some(p => p.type === vehicleType)) {
                toast.error("Tipo já adicionado. Por favor, adicione um tipo diferente ou remova o anterior.");
                return;
            }
        }
        const newPrices = deletedPrice ? oldPrices : [...oldPrices, { type: vehicleType, value: price }];
        setPrices(newPrices);
        setPendingChanges([...pendingChanges, garageId!])
        let garageData = JSON.parse(sessionStorage.getItem(`garage${garageId}`) || '{}');
        garageData.price = newPrices;
        sessionStorage.setItem(`garage${garageId}`, JSON.stringify(garageData));
        toast.success("Preço Adicionado com sucesso.")
        toggleModalPrices();
        setPrice(0);
        setVehicleType("selecione");
        setDeletedPrice(false);
    }
    const compareTimes = (time1: string, time2: string) => {
        const [hours1, minutes1] = time1.split(":").map(Number);
        const [hours2, minutes2] = time2.split(":").map(Number);

        const date1 = new Date();
        date1.setHours(hours1, minutes1);

        const date2 = new Date();
        date2.setHours(hours2, minutes2);

        if (date1 > date2) {
            return 1;
        } else if (date1 < date2) {
            return -1;
        } else {
            return 0;
        }
    };
    const handleEditSchedules = () => {
        const oldSchedules = Array.isArray(schedules) ? schedules : [];
        if (!deletedSchedule) {
            if(dayOfTheWeek === "Dia da semana") {
                toast.error("Dia da semana não preenchido.")
                return;
            }
            if (!startTime || !endTime) {
                toast.error("Preencha o campo de início e fim.")
                return;
            }
            if (compareTimes(startTime, endTime) >= 0) {
                toast.error("A hora de início tem de ser inferior à hora de fim.")
                return;
            }
            // Check for repeated schedule
            if (oldSchedules.some(p => p.day === vehicleType 
                && p.open === startTime && p.close === endTime)) {
                toast.error("Horário já adicionado. Por favor, adicione um horário diferente.");
                return;
            }
        }
        
        const newSchedules = deletedSchedule ? oldSchedules : [...oldSchedules, { day: dayOfTheWeek, open: startTime, close: endTime }];
        setSchedules(newSchedules);
        toast.success("Horário Adicionado com sucesso.")
        toggleModalSchedules();
        setPendingChanges([...pendingChanges, garageId!])
        let garageData = JSON.parse(sessionStorage.getItem(`garage${garageId}`) || '{}');
        garageData.schedule = newSchedules;
        sessionStorage.setItem(`garage${garageId}`, JSON.stringify(garageData));
        setDayOfTheWeek("Dia da semana");
        setStartTime("09:00");
        setEndTime("10:00");
        setDeletedSchedule(false);

    }

    /*
    Modals setup (open/close functions and variables)
    */
    const toggleModalServices = () => {
        setIsOpenServices(!isOpenServices);
    };
    const toggleModalPrices = () => {
        setIsOpenPrices(!isOpenPrices);
    };
    const toggleModalSchedules = () => {
        setIsOpenSchedules(!isOpenSchedules);
    };
    const toggleShowHelp = ({ bool }: { bool: boolean }) => {
        setShowHelp(bool);
    }
    /*
    drag and drop functions for the services modal
    */

    //drop function for the services modal with drag and drop
    function handleDrop(e: DragEvent<HTMLDivElement>, target: string) {
        const data = e.dataTransfer.getData('text');
        if (target === 'services' && !servicesModal.includes(data)) {
            setServicesModal(prev => [...prev, data]);
            setActiveServicesModal(prev => prev.filter(item => item !== data));
        } else if (target === 'active' && !activeServicesModal.includes(data)) {
            setActiveServicesModal(prev => [...prev, data]);
            setServicesModal(prev => prev.filter(item => item !== data));
        }
        setDraggingFrom(null);
    }

    //drag start function for the services modal with drag and drop
    function handleDragStart(e: DragEvent<HTMLDivElement>, data: string, source: string) {
        e.dataTransfer.setData('text/plain', data);
        setDraggingFrom(source);
    }

    //save the active services after the user saves the changes
    const handleSaveServices = () => {
        setPendingChanges([...pendingChanges, garageId!])
        setActiveServices(activeServicesModal)
        let garageData = JSON.parse(sessionStorage.getItem(`garage${garageId}`) || '{}');
        garageData.services = activeServicesModal;
        sessionStorage.setItem(`garage${garageId}`, JSON.stringify(garageData));
        toast.success("Serviços Ativos Alterados.")
        toggleModalServices();
    }
    //update the marker on the map after the user changes the location
    const handlePosChange = () => {
        setPendingChanges([...pendingChanges, garageId!])
        const map = mapRef.current as L.Map;
        if (markerMap.current) {
            map.removeLayer(markerMap.current);
            if (auxMarkerMap.current) {
                map.removeLayer(auxMarkerMap.current);
            }
        }
        setAddressCoords(auxAddressCoords);
        markerMap.current = L.marker(auxAddressCoords, { icon }).addTo(map);
        markerMap.current.bindPopup(address);
        let garageData = JSON.parse(sessionStorage.getItem(`garage${garageId}`) || '{}');
        garageData.address = { address: address, latitude: auxAddressCoords[0], longitude: auxAddressCoords[1] };
        sessionStorage.setItem(`garage${garageId}`, JSON.stringify(garageData));
        toast.success("Localização Alterada com sucesso");

    }

    useEffect(() => {
        if (showHelp || isOpenSchedules || isOpenPrices || isOpenServices) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px'; //Padding to prevent page from shifting
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
        }
    }, [showHelp, isOpenSchedules, isOpenPrices, isOpenServices]);

    /*
    Map setup
    */



    useEffect(() => {
        if (mapRef.current) {
            return;
        }


        //setting up the map
        const center = [
            32.778760,
            -6.167488
        ];
        mapRef.current = L.map("map", { attributionControl: false, zoomControl: false, doubleClickZoom: false }).setView(center as LatLngExpression, 15);

        const map = mapRef.current;

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        }).addTo(map);

        /*
        Geocoder for the map, allows to search for a location based on the address
        (conversion of address to coordinates)
        */
        const geocode = new Geocoder({
            collapsed: true,
            position: 'topright',
            placeholder: 'Pesquise Pelo Endereço',
            defaultMarkGeocode: false
        }).addTo(map);

        if (geocode.options.geocoder) {
            geocode.options.geocoder.geocode(address, (results) => {
                const latlng = results[0].center;
                setAddressCoords([latlng.lat, latlng.lng]);
                map.setView(latlng, 16);
                markerMap.current = L.marker(map.getCenter(), { icon }).addTo(map);
                markerMap.current.bindPopup(address);
            });
        }

        /* 
        conversion of address to coordinates based on search
        (as default it is used the address)
        */
        geocode.on('markgeocode', e => {
            const coords = [e.geocode.center.lat, e.geocode.center.lng];
            map.setView(coords as LatLngExpression, 16);//center map based on the searched location
        });

        /*
        SETUP MAP
        add marker to the address of the garage
        */


        //double click to set address
        mapRef.current.on('dblclick', async function (e) {
            const lat = e.latlng.lat;
            const lng = e.latlng.lng;

            // Reverse geocoding (coordinates to address)
            const provider = new OpenStreetMapProvider();
            const results = await provider.search({ query: `${lat}, ${lng}` });
            //set the map center to the selected location
            map.setView([lat, lng] as LatLngExpression, 16);
            setAuxAddressCoords([lat, lng]);


            //different icon used for the auxilary marker
            const icon = L.icon({
                iconUrl: pin,
                iconSize: [50, 50],
            });

            /*
            Auxilary marker is used so that the user can see the address of the location he is selecting
            includes a popup with the address on click on the marker
            */
            if (auxMarkerMap.current) {
                //remove previous auxilary marker
                map.removeLayer(auxMarkerMap.current);
            }
            auxMarkerMap.current = L.marker([lat, lng], { icon }).addTo(map).bindPopup(results[0].label);


            if (results[0]) {
                setAddress(results[0].label);
                geocode.setQuery(results[0].label);//set the search bar in the map to match the address
            }
        });

    }, []);

    useEffect(() => {
        if (mapRef.current && mapRef.current instanceof L.Map) {
            mapRef.current.setView(addressCoords as LatLngExpression, 16);
            if (markerMap.current) {
                mapRef.current.removeLayer(markerMap.current);
                markerMap.current = L.marker(addressCoords, { icon }).addTo(mapRef.current);
                markerMap.current.bindPopup(address);
            }
        }
    }, [addressCoords]);


    //GET BY ID


    useEffect(() => {
        const fetchGarage = async () => {
            setLoading(true);
            try {
                const activeGarage = activeGarages.filter((garage: { name: string | undefined; }) => garage.name === garageId);
                const activeGarageId = activeGarage[0]._id;
                let data;

                // Check if data is in session storage
                const storedData = sessionStorage.getItem(`garage${garageId}`);
                if (storedData) {
                    data = JSON.parse(storedData);
                } else {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/garage/${activeGarageId}`, {
                        headers: {
                            Authorization: `${user!.accessToken}`
                        }
                    });

                    data = response.data.data;

                    // Store data in session storage
                    sessionStorage.setItem(`garage${garageId}`, JSON.stringify(data));
                }

                //UPDATE THE SETTERS WITH THE DATA
                setImagesUrls(data.images);
                setEmail(data.email);
                setContact(data.phoneNumber);
                setAddressCoords([data.address.latitude, data.address.longitude])
                setAddress(data.address.address);
                setChargers(data.chargers);
                setActiveServices(data.services);
                setPrices(data.price);
                setSchedules(data.schedule);
                setId(data._id);

            } catch (error) {
                console.error('Error fetching garage:', error);
                toast.error('Erro ao carregar a sua garagem. Tente novamente mais tarde.');
            }
            setLoading(false);
        }

        fetchGarage();
    }, [garageId]);


    const handlePrice = (value: string | SetStateAction<number>) => {
        if (!isNaN(Number(value))) {
            setPrice(Number(value))
        }
    }

    const handleNavigation = (direction: string) => {
        navigate(direction);
    }

    function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const fileNames = [];
            for (let i = 0; i < event.target.files.length; i++) {
                let file = event.target.files[i];
                let fileType = file["type"];
                let validImageTypes = ["image/jpg", "image/png"];
                if (!validImageTypes.includes(fileType)) {
                    toast.error("Tipo inválido. Apenas .png e .jpg são válidos.");
                    event.target.value = "";
                    return;
                }
            }
            if (event.target.files.length + imagesUrls.length > 10) {
                toast.error("Apenas pode adicionar até 10 imagens no total.");
                event.target.value = "";
            } else {
                setSelectedImages(event.target.files);
                let images = imagesUrls;
                for (let i = 0; i < event.target.files.length; i++) {
                    fileNames.push(event.target.files[i].name);
                    setImagesUrls([...imagesUrls, URL.createObjectURL(event.target.files[i])]);
                    images.push(URL.createObjectURL(event.target.files[i]));
                }
                let garageData = JSON.parse(sessionStorage.getItem(`garage${garageId}`) || '{}');
                garageData.images = images;
                sessionStorage.setItem(`garage${garageId}`, JSON.stringify(garageData));
                toast.success("Imagens selecionadas: " + fileNames.join(", "), { duration: 5000 });
            }
        }
    }

    const handleSave = async () => {
        if (isNaN(Number(contact))) {
            toast.error('O contacto tem de ser um valor numérico.');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Email inválido.');
            return;
        }

        if (!isPhoneValid(contact)) {
            toast.error('Contacto inválido.');
            return;
        }

        setLoading(true);



        const uploadedImages = selectedImages ? await uploadToFirebase(Array.from(selectedImages), 'garages/images', user!.email) : null;

        if (!uploadedImages && selectedImages) {
            toast.error('Erro ao fazer upload dos ficheiros. Tente novamente.');
            setLoading(false);
            return;
        }


        let data: Garage_get = {
            phoneNumber: contact,
            address: {
                address: address,
                latitude: addressCoords[0],
                longitude: addressCoords[1]
            }
        };

        if (uploadedImages) {
            if (uploadedImages.length > 0) {
                data = {
                    ...data,
                    images: uploadedImages!
                }
            }
        }



        if (schedules !== undefined && schedules.length > 0) {
            data = {
                ...data,
                schedule: schedules
            }
        }


        if (chargers !== undefined && chargers.length > 0) {
            data = {
                ...data,
                chargers: chargers
            }
        }

        if (activeServices !== undefined && activeServices.length > 0) {
            data = {
                ...data,
                services: activeServices
            }
        }

        if (prices !== undefined && prices.length > 0) {
            data = {
                ...data,
                price: prices
            }
        }


        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/garage/update/${id}`, data, {
                headers: {
                    'Authorization': user!.accessToken
                }
            });

            if (response.data.condition === "success") {
                setSelectedImages(null);
                const newUser = {
                    ...user!,
                    garages: user!.garages.map(garage =>
                        garage._id === id ? { ...garage, ...data } : garage
                    ),
                };
                setUser(newUser);
                sessionStorage.setItem('user', JSON.stringify(newUser));
                if (Array.isArray(pendingChanges)) {
                    const garageIndex = pendingChanges.filter(change => change !== garageId);
                    setPendingChanges(garageIndex);
                }
                toast.success('Garagem atualizada com sucesso!');
            }
        } catch (error) {
            toast.error('Erro ao fazer o update. Tente novamente.');
            console.error('Error updating garage:', error);
        }
        setLoading(false);
    }

    return (
        <Page >
            <Toaster />
            <Header title='My Garage' value={garageId} options={user?.garages ? user?.garages.filter((garage: { active: boolean; }) => garage.active).map((garage) => garage.name) : []} />
            <div className="text-end right-20 pt-8 absolute flex gap-4 cinco:gap-8 flex-col cinco:flex-row">
                <button onClick={() => handleNavigation("preview")} className="bg-blue-500 text-lg text-white px-4 py-2 rounded-lg hover:scale-110 transition-transform duration-200">Preview</button>
                <button onClick={handleSave} className="bg-blue-500 text-lg text-white px-4 py-2 rounded-lg hover:scale-110 transition-transform duration-200">Guardar</button>
            </div>
            <div className="pt-8 seis:px-[4vw] px-[8vw]">
                <button onClick={() => handleNavigation("/home")}>
                    <div className="flex text-lg font-extrabold w-fit cursor-pointer "><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></div>
                </button>
            </div>
            <div className=" py-12 seis:px-[4vw] px-[8vw] pb-20">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-20">
                    <div className="rounded-lg">
                        <div className="flex items-center justify-between pb-4">
                            {imagesUrls ? <h2 className="text-xl font-semibold">Imagens ({imagesUrls?.length})</h2> : <h2 className="text-xl font-semibold">Imagens</h2>}
                            <div className="relative">
                                <label title="Click to upload" htmlFor="buttonImagesEdit" className="relative z-10 cursor-pointer hover:scale-105 transition-transform duration-200 flex px-4 py-2 rounded-xl gap-3 min-w-40">
                                    <label htmlFor="buttonImagesEdit" className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer">
                                        <IoAddCircle color="black" className="cursor-pointer hover:scale-110 transition-transform duration-200" size={32} title="Adicionar" />
                                    </label>
                                </label>
                                <input hidden type="file" name="buttonImagesEdit" id="buttonImagesEdit"
                                    className="w-full"
                                    accept=".png, .jpg"
                                    multiple
                                    onChange={handleImageUpload}
                                />
                            </div>
                        </div>
                        <div className="pb-4 gap-2">
                            {imagesUrls && <CarouselGarage type="garageImages" multiple={imagesUrls!.length > 1}>
                                {imagesUrls?.map((img, index) => (
                                    <div key={index} className="w-[80%] cinco:w-[90%] h-[15rem] cinco:h-[25rem] bg-cover bg-center bg-no-repeat rounded-3xl" style={{ backgroundImage: `url(${img})` }}>
                                    </div>
                                ))}
                            </CarouselGarage>}

                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="flex items-center justify-between pb-4 w-1/2">
                            <h2 className="text-xl font-semibold">Preço</h2>
                            <MdEdit className="cursor-pointer hover:scale-110 transition-transform duration-200" size={32} title="Editar" onClick={toggleModalPrices} />
                        </div>
                        <div className="flex items-center gap-4 w-11/12">
                            {prices ? <div className="space-y-4">
                                <p className="text-xl">Tipo</p>
                                {prices.map((item, index) => (
                                    <div key={index} className="flex text-center gap-4">
                                        <p className="rounded-full w-32 border border-solid border-black py-2">
                                            {item.type}
                                        </p>
                                        <p className="rounded-full w-32 border border-solid border-black py-2">
                                            {item.value.toString()}
                                        </p>
                                    </div>
                                ))}
                            </div> : <p className="text-xl">Adicione um novo preço</p>}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-20 pt-10">
                    <div >
                        <h2 className="text-xl font-black pb-4">Contactos</h2>
                        <div className='py-2 flex flex-col'>
                            <label className="text-lg text-black w-full">Email</label>
                            <input onChange={(e) => setEmail(e.target.value)} value={email} disabled placeholder="Email" className="bg-transparent rounded-full py-2 px-4 w-60 cinco:w-96 border border-solid border-black" />
                        </div>
                        <div className='flex flex-col py-4'>
                            <label className="text-lg text-black w-full">Contacto</label>
                            <div className=" w-60 cinco:w-96 border border-solid border-black py-1 px-6 rounded-full bg-transparent">
                                <MyPhoneInput contact={contact} setContact={setContact} />
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg ">
                        <div className="flex items-center justify-between pb-4 w-1/2">
                            <h2 className="text-xl font-semibold">Horário</h2>
                            <MdEdit className="cursor-pointer hover:scale-110 transition-transform duration-200" size={32} title="Editar" onClick={toggleModalSchedules} />
                        </div>
                        {schedules ? schedules.map((item, index) => (
                            <div key={index} className="flex gap-2 cinco:gap-8 py-2">
                                <p className="bg-transparent text-center w-40 rounded-full py-2 border border-solid border-black" >{item.day}</p>
                                <p className="bg-transparent text-center w-24 rounded-full py-2 border border-solid border-black" >{item.open}</p>
                                <p className="bg-transparent text-center w-24 rounded-full py-2 border border-solid border-black" >{item.close}</p>
                            </div>
                        )) : <p className="text-xl">Adicione um novo horário</p>}
                    </div>

                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-20 pt-10">
                    <div className="garageMap rounded-xl">
                        <div className="flex pb-4">
                            <div className="pr-2 flex items-center">
                                <p className="text-xl font-semibold">Local</p>
                            </div>
                            <IoHelpCircleOutline className="cursor-pointer hover:scale-110 transition-transform duration-200" title="Ajuda" onClick={() => toggleShowHelp({ bool: true })} size={32} />
                            <Help toggleShowHelp={(bool: boolean) => toggleShowHelp({ bool })} showHelp={showHelp} />
                        </div>
                        <div id="map" />
                        <div className="flex flex-col pt-6 pl-4">
                            <p className="w-full bg-transparent text-black text-xl" >{address}</p>
                            <button onClick={handlePosChange} className="underline w-fit text-black text-lg underline-offset-2">Guardar Alteração</button>
                        </div>
                    </div>
                    <div>
                        <div className="rounded-lg">
                            <div className="flex items-center justify-between pb-4">
                                {chargers ? <h2 className="text-xl font-semibold">Carregadores ({chargers!.length})</h2> : <h2 className="text-xl font-semibold">Carregadores</h2>}
                                <button onClick={() => setPopup(true)} className="bg-[#7ed957] px-6 py-2 rounded-xl hover:scale-110 transition-transform duration-200">Add</button>
                            </div>
                            {chargers && <div>
                                {chargers.length > 0 && <CarouselGarage type="chargersGarage" multiple={chargers.length > 0}>
                                    {chargers?.map((charger, index) => (
                                        <div key={index} className="pr-8">
                                            <div className="text-xl font-semibold px-4 py-4 bg-gray-200 border border-solid border-black rounded-2xl h-40 flex flex-col gap-2">
                                                <h2 className="text-[var(--primary)] pb-3">{charger.type}</h2>
                                                <h2>Power: {charger.power}</h2>
                                                <h2>Nº: 1{charger.quantity}</h2>
                                            </div>
                                        </div>
                                    ))}
                                </CarouselGarage>}
                            </div>}
                        </div>
                        <div className="rounded-xl pt-10">
                            <h2 className="text-xl font-semibold pb-4">Serviços</h2>
                            <div className="flex gap-4">
                                {activeServices && activeServices.map((item, index) => (
                                    <div key={index} className="flex items-center"><ServiceIcon serviceName={item} /></div>
                                ))}
                                <IoAddCircle className="cursor-pointer hover:scale-110 transition-transform duration-200" size={38} title="Adicionar" onClick={toggleModalServices} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpenServices}>
                <IoClose onClick={toggleModalServices} title="Fechar" className="absolute top-2 right-4 cursor-pointer" size={32} />
                <div className="rounded-lg px-8 py-14 seis:p-14 w-full">
                    <div className="flex justify-between items-center pb-6">
                        <h2 className="font-black text-2xl pr-4">Adicionar Serviço</h2>
                        <button className="bg-blue-500 text-white font-black text-lg py-2 px-10 rounded-lg hover:scale-110 transition-transform duration-200" onClick={handleSaveServices}>Salvar</button>
                    </div>
                    <div className="flex justify-center">
                        <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, 'options')}>
                            <h3 className="font-black pb-4 text-xl">Opções</h3>
                            <div className="min-h-full space-y-4  w-full" onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, 'services')}>
                                {servicesModal.map((item, index) => (
                                    <div key={index} draggable="true" onDragStart={(e) => handleDragStart(e, item, 'options')} className="seis:flex text-center border border-solid w-32 seis:w-48 gap-4 border-black bg-transparent rounded-full p-2">
                                        <div className="flex justify-center seis:flex-none"><ServiceIcon serviceName={item} /></div>
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-center items-center px-2 seis:px-8">
                            <div>
                                <div className="flex justify-center items-center h-fit pt-10 pb-10">
                                    <FaArrowRight color={draggingFrom === 'options' ? 'blue' : 'black'} className="transition-colors duration-300 text-4xl quatro:text-5xl" />
                                </div>
                                <div className="flex justify-center items-center h-fit">
                                    <FaArrowLeft color={draggingFrom === 'assets' ? 'blue' : 'black'} className="transition-colors duration-300 text-4xl quatro:text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, 'assets')}>
                            <h3 className="font-black text-xl pb-4 text-blue-500">Ativos</h3>
                            <div className="min-h-full space-y-4  w-full" onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, 'active')}>
                                {activeServicesModal.map((item, index) => (
                                    <div key={index} draggable="true" onDragStart={(e) => handleDragStart(e, item, 'assets')} className="seis:flex text-center  border border-solid w-32 seis:w-48 gap-4  border-black bg-transparent rounded-full p-2">
                                        <div className="flex justify-center seis:flex-none"><ServiceIcon serviceName={item} /></div>
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isOpenPrices} >
                <div className="relative">
                    <IoClose onClick={toggleModalPrices} title="Fechar" className="absolute top-2 right-4 cursor-pointer" size={32} />
                    <div className="px-10 seis:px-20 py-10">
                        <div className="flex pt-10 seis:pt-0 justify-between items-center gap-4 pb-10">
                            <h1 className="text-xl font-semibold">Tabela de Preços</h1>
                            <button onClick={handleEditPrices} className="bg-blue-500 text-lg  text-white py-2 px-8 rounded-lg shadow-lg hover:scale-110 transition-transform duration-200 ">
                                Salvar
                            </button>
                        </div>
                        <div className="overflow-x-auto ">
                            <table className="w-full text-left">
                                <thead>
                                    <tr>
                                        <th className="border border-zinc-400 px-4 py-2">Tipo</th>
                                        <th className="border border-zinc-400 px-4 py-2">Preço Atual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prices && prices.map((item, index) => (
                                        <tr key={index} >
                                            <td className="border border-zinc-400 px-4 py-2">{item.type}</td>
                                            <td className="border border-zinc-400 px-4 py-2">{item.value.toString()}</td>
                                            <td className="text-center">
                                                <div className="flex justify-center w-full px-4">
                                                    <FaRegTrashAlt className="cursor-pointer" color="#5d5fef" title="Remover" onClick={() => { setPrices(prevPrices => prevPrices.filter(price => price.type !== item.type)); setDeletedPrice(true) }} size={22} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td onClick={() => setOpenVehicleType(!isOpenVehicleType)} className="border border-zinc-400 px-4 py-2">
                                            <div className="flex justify-between">
                                                {vehicleType}
                                                <IoMdArrowDropdown size={22} />
                                            </div>
                                            {isOpenVehicleType && <div className={`flex gap-1 flex-col absolute top-[93%] bg-white rounded-xl transition-opacity ease-in-out duration-200 border border-solid border-zinc-400 p-2`}>
                                                {vehicleOptions.map((vehicle, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            onClick={() => { setVehicleType(vehicle.value); setOpenVehicleType(false) }}
                                                            className={`${vehicleType === vehicle.value ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>
                                                            {vehicle.value}
                                                        </button>
                                                    )
                                                }
                                                )}
                                            </div>}
                                        </td>
                                        <td className="border border-zinc-400 px-4 py-2">
                                            <input className="w-full h-full focus:outline-none" onChange={(e) => handlePrice(e.target.value)} type="text" placeholder="Insira o preço" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isOpenSchedules} >
            <div className="relative">
                <IoClose onClick={toggleModalSchedules} title="Fechar" className="absolute top-2 right-4 cursor-pointer" size={32} />
                <div className="px-4 sete:px-20 py-10">
                    <div className="flex pt-8 sete:pt-0 justify-between items-center gap-4 pb-10">
                        <h1 className="text-xl font-semibold">Horários</h1>
                        <button onClick={handleEditSchedules} className="bg-blue-500 text-lg  text-white py-2 px-8 rounded-lg shadow-lg hover:scale-110 transition-transform duration-200 ">
                            Salvar
                        </button>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <th className="border border-zinc-400 px-4 py-2">Dia da Semana</th>
                                    <th className="border border-zinc-400 px-4 py-2">Hora de Início</th>
                                    <th className="border border-zinc-400 px-4 py-2">Hora de Fim</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schedules && schedules.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border border-zinc-400 px-4 py-2">{item.day}</td>
                                        <td className="border border-zinc-400 px-4 py-2">{item.open}</td>
                                        <td className="border border-zinc-400 px-4 py-2">{item.close}</td>
                                        <td className="text-center">
                                            <div className="flex justify-center w-full px-4">
                                                <FaRegTrashAlt className="cursor-pointer" color="#5d5fef" title="Remover"
                                                    onClick={() => {
                                                        setSchedules(prevSchedules => prevSchedules.filter((_, idx) => idx !== index));setDeletedSchedule(true)
                                                    }} size={22} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td onClick={() => setOpenDay(!isOpenDay)} className="border border-zinc-400 px-4 py-2">
                                        <div className="flex justify-between">
                                            {dayOfTheWeek}
                                            <IoMdArrowDropdown size={22} />
                                        </div>
                                        {isOpenDay && <div className={`flex gap-1 flex-col absolute top-[93%] bg-white rounded-xl transition-opacity ease-in-out duration-200 border border-solid border-zinc-400 p-2`}>
                                            {daysOfTheWeek.map((value, index) => {
                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => { setDayOfTheWeek(value); setOpenDay(false) }}
                                                        className={`${dayOfTheWeek === value ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>
                                                        {value}
                                                    </button>
                                                )
                                            }
                                            )}
                                        </div>}
                                    </td>

                                    <td className="border border-zinc-400 px-4 py-2">
                                        <input className="h-full focus:outline-none" type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                                    </td>
                                    <td className="border border-zinc-400 px-4 py-2">
                                        <input className="h-full focus:outline-none" type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            </Modal>
            <AddCharger popup={popup} setPopup={setPopup} onEdit={true} garageId={garageId} chargers={chargers} setChargers={setChargers} />
            <Loading isOpen={loading} />
        </Page>
    );

}

