
import { FaCar, FaMotorcycle, FaTruck, FaCaravan } from 'react-icons/fa';
import { FaExclamationCircle } from "react-icons/fa";

const VehicleOptionsComponent = () => {
    const vehicleOptions = [
        { value: 'carro', icon: <FaCar title='carro' size={32}/> },
        { value: 'mota', icon: <FaMotorcycle title='mota' size={32}/> },
        { value: 'caravana', icon: <FaCaravan title='caravana' size={32}/>  },
        { value: 'camião', icon: <FaTruck title='camião' size={32}/> }
    ];

    const findIconByValue = (value: string) => {
        const option = vehicleOptions.find(option => option.value === value);
        return option ? option.icon : <FaExclamationCircle title='not found' size={32}/>;
    }

    return { vehicleOptions, findIconByValue };
}

export default VehicleOptionsComponent;