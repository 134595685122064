import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineLogout } from "@react-icons/all-files/hi/HiOutlineLogout";
import { IoIosPeople } from "react-icons/io";
import { BsBriefcaseFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { TfiStatsUp } from "react-icons/tfi";
import Hamburger from 'hamburger-react'
import Logo from '../content/images/icons/logo.png';
import { useState } from 'react';
import { useUser } from '../contexts/userInfo-context';
import { usePendingChanges } from '../contexts/pendingChanges-context';
import toast from 'react-hot-toast';
import { IoIosWarning } from "react-icons/io";

const Navbar = ({ windowWidth }: { windowWidth: number }) => {
    const [pendingChanges] = usePendingChanges();
    const navigate = useNavigate();
    const { setUser } = useUser();
    interface CustomLinkProps {
        href: string;
        icon?: React.ComponentType<{ size?: number }>;
        children?: React.ReactNode;
        size?: number;
        top?: boolean;
    }

    const CustomLink: React.FC<CustomLinkProps> = ({ href, icon: Icon, children = null, size = 24, top = false }) => {
        let selected = (window.location.pathname.includes(href)
            || (href === '/post' && window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1] === 'post'));
        return (
            <li className={`oitocentos:px-8  ${!selected ? 'text-[var(--gray)] hover:text-black' : `text-[var(--primary)] ${top ? '' : 'border-r-4'} border-[var(--primary)]`}`}>
                <Link to={href} className="flex items-center gap-4">
                    {Icon && <Icon size={size} />}
                    {children && <p className='text-[1rem] font-bold text-xl'>{children}</p>}
                </Link>
            </li>
        )
    }

    const handleLogout = () => {
        if (pendingChanges.length > 0) {
            const uniquePendingChanges = Array.from(new Set(pendingChanges)).join(', ');
            toast(`Existem alterações pendentes nas garagens ${uniquePendingChanges}. Por favor, guarde as alterações antes de sair.`, {
                duration: 5000,
                icon: <IoIosWarning size={70} />,

            });
            return;
        } else {
            setUser(null); // clear the user info
            sessionStorage.clear(); // clear the session storage
            const remember = JSON.parse(localStorage.getItem('remember') || 'false');
            if(!remember){
                localStorage.clear(); // clear the local storage
            }
            navigate('/'); // redirect to login page
        }
    };

    const NavBarLeft = () => {
        
        return (
            <div id='navbar' className='fixed flex flex-col w-[17rem] items-center oitocentos:items-start justify-between min-h-screen h-full bg-[white] p-4 pr-0 oitocentos:p-8 oitocentos:pr-0 text-gray pt-8 oitocentos:pt-8 z-[10] pb-8'>
                <div className='flex flex-col w-full gap-16 pb-4'>
                    <div className='flex flex-col items-center w-full oitocentos:items-end'>
                        <img src={Logo} alt="logo" className={`w-[20rem]`} />
                        <h2 className='text-[1rem] text-[var(--primary)] font-bold text-right w-full pr-4'>Garage</h2>
                    </div>
                    <ul className='flex flex-col gap-8 pl-8'>
                        <CustomLink icon={BsBriefcaseFill} href="/home" >Garagens</CustomLink>
                        <CustomLink icon={IoIosPeople} href="/clients" >Clientes</CustomLink>
                        <CustomLink icon={TfiStatsUp} href="/statistics" >Estatísticas</CustomLink>
                        <CustomLink icon={FaFileInvoiceDollar} href="/billing" >Faturação</CustomLink>
                    </ul>
                </div>
                <hr className="w-[80%] border-t border-gray-300" />

                <div className='w-full pl-8 pt-4'>
                    <ul className='flex flex-col gap-8 pb-6'>
                        <CustomLink icon={IoMdSettings} href="/settings" >Definições</CustomLink>
                    </ul>
                    <button onClick={handleLogout} className={`oitocentos:px-8 text-[var(--gray)] hover:text-black`}>
                        <div className={`flex items-center gap-4`}>
                            <HiOutlineLogout size={24} className='group-hover:text-[black]' />
                            <p className='text-[1rem] font-bold group-hover:text-[black] text-xl'>Logout</p>
                        </div>
                    </button>
                </div>
            </div>
        )
    }

    const NavDropdown = () => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div id='navbar' className={`fixed w-full text-gray z-50 transition-all duration-500 ease-in-out ${isOpen ? 'h-screen' : 'h-0'}`}>
                <div className={`flex flex-row h-[6rem] bg-[white] items-center justify-between px-[6vw] quatro:px-[8vw] w-full`}>
                    <div className='w-[15rem] seis:w-[20rem]'>
                        <img src={Logo} alt="logo" className='w-full transition-all duration-500 ease-in-out' />
                    </div>
                    <Hamburger toggled={isOpen} toggle={setIsOpen} />
                </div>
                <div className={`transition-all duration-500 ease-in-out overflow-hidden  px-[6vw] quatro:px-[8vw] bg-white h-full ${isOpen ? 'h-screen' : 'h-0'}`}>
                    <ul className='flex flex-col h-full gap-6 py-10'>
                        <CustomLink icon={BsBriefcaseFill} href="/home" top children="Garage" />
                        <CustomLink icon={IoIosPeople} href="/clients" top children="Clients" />
                        <CustomLink icon={TfiStatsUp} href="/statistics" top children="Statistics" />
                        <CustomLink icon={FaFileInvoiceDollar} href="/billing" top children="Billing" />
                        <CustomLink icon={IoMdSettings} href="/settings" top children="Settings" />
                        <button onClick={handleLogout} className={`text-[var(--gray)] hover:text-black`}>
                            <div className={`flex items-center gap-4`}>
                                <HiOutlineLogout size={24} className='group-hover:text-[black]' />
                                <p className='text-[1rem] font-bold group-hover:text-[black] text-xl'>Logout</p>
                            </div>
                        </button>
                    </ul>
                </div>
            </div>
        )
    }




    return (
        <>
            {windowWidth >= 900 ? <NavBarLeft /> : <NavDropdown />}
        </>
    );
}

export default Navbar;

