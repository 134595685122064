import React, { createContext, useContext, useState, useEffect } from 'react';

const WindowWidthContext = createContext(0);

export const WindowWidthProvider = ({  children }: { children: React.ReactNode }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={windowWidth}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export const useWindowWidth = () => useContext(WindowWidthContext);