import { Transition } from "react-transition-group";
import { IoClose} from "react-icons/io5";

const Help = ({ toggleShowHelp, showHelp }: { toggleShowHelp: (arg0: boolean) => void; showHelp: boolean }) => {
    const transitionDuration = 200;


    return (
        <Transition in={showHelp} timeout={transitionDuration} unmountOnExit>
            {(state) => (
                <div
                    className={`fixed select-none top-1/2 translate-y-[-50%] z-50 w-96 right-1/2 translate-x-[50%] flex p-3 gap-3 bg-zinc-300 rounded-2xl shadow-2xl transition-opacity duration-${transitionDuration} ${state === 'entered' ? 'opacity-100' : 'opacity-0'}`}
                >
                    <IoClose
                        onClick={() => toggleShowHelp(!showHelp)}
                        title="Fechar"
                        className="absolute top-2 right-4 cursor-pointer"
                        size={32}
                    />
                    <div className="gap-3 w-full">
                        <p className="font-black text-xl text-zinc-600 text-center">Ajustar posição no mapa</p>
                        <p className="text-lg pt-4 text-black">Poderá escolher um ponto no mapa para a localização da sua garagem. Para isso apenas terá de selecionar o ponto fazendo double click.</p>
                        <p className="text-lg pt-4 text-black">Poderá, também, procurar pela morada na barra de pesquisa.</p>
                    </div>
                </div>
            )}
        </Transition>
    );
};

export default Help;