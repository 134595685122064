import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Garage } from './interfaces/interfaces';




const GarageCard = ({ garage } : {garage: Garage}) => {
    const navigate = useNavigate();
    const placeholderImage = process.env.PUBLIC_URL + "/R.jpeg";
    const [imgSrc, setImgSrc] = useState(placeholderImage);

    useEffect(() => {
        const img = new Image();
        img.src = garage.logo!;
        img.onload = () => setImgSrc(garage.logo!);
    }, [garage.logo]);

    const handleClick = () => {
        if (garage.active || garage.status === 'active') {
            navigate(`/home/garage/${garage.name}`);
        }
    };

    return (
        <div onClick={handleClick} className={`bg-white rounded-[40px] shadow-lg p-8 border-2 border-solid border-black ${garage.active || garage.status === 'active' ? "cursor-pointer" : ""}`}>
            <div className="flex justify-between pb-8">
                <div>
                    <p className="text-lg font-black">{garage.name}</p>
                    {garage.address && <p className="text-lg font-black">{garage.address.address}</p>}
                </div>
                {/* garage.logo */}
                <img
                    className="w-20 h-20 rounded-[20%]"
                    src={imgSrc}
                    alt={`garage ${garage.name}`}
                />
            </div>
            {garage.totalSpots && <p className="text-lg font-black">Nº de lugares: {garage.totalSpots}</p>}
            {/* <p className="text-lg font-black">Tipos: Avença e Tarifa</p> */}
        </div>
    );
};

export default GarageCard;