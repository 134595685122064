import Service1 from '../content/images/icons/parkLight.png';
import Price from '../content/images/icons/price.png';
import ChargerIcon from '../content/images/icons/charger.png';
import Service2 from '../content/images/icons/cash-1.png';
import { Transition } from "react-transition-group";
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { usePendingChanges } from '../contexts/pendingChanges-context';
import { Charger } from './interfaces/interfaces';


export default function AddCharger({ setPopup, chargers, setChargers, popup, onEdit = false, garageId = "" }: { setPopup: any, chargers: Charger[], setChargers: any, popup: boolean, onEdit?: boolean, garageId?: string }) {
    const transitionDuration = 300;
    const [pendingChanges, setPendingChanges] = usePendingChanges();
    const [type, setType] = useState("");
    const chargerTypes = [
        { type: "30 KWa/H", power: 30, quantity: 1 },
        { type: "50 KWa/H", power: 50, quantity: 1 },
        { type: "150 KWa/H", power: 150, quantity: 1 },
    ];

    const handleClose = () => {
        setPopup(false);
        setType("");
    }

    const handleAddCharger = (type: string) => {
        const newChargers = [...chargers, { type, power: chargerTypes.find(charger => charger.type === type)!.power, quantity: chargerTypes.find(charger => charger.type === type)!.quantity }]
        setChargers(newChargers);
        toast.success(`Carregador ${type} adicionado com sucesso`);
        setPopup(false);
    }

    useEffect(() => {
        if (chargers) {
            if (chargers[chargers.length - 1]) {
                setType(chargers[chargers.length - 1].type);
                if (onEdit) {
                    setPendingChanges([...pendingChanges, garageId!])
                }
            }
        }
    }, [chargers])

    return (
        <Transition in={popup} timeout={transitionDuration} unmountOnExit>
            {(state) => (
                <div className={`fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[rgba(.5,.5,.5,.3)] z-[99] ease-in-out transition-opacity duration-${transitionDuration} ${state === 'entered' ? 'opacity-100' : 'opacity-0'}`}>
                    <div className='w-full h-full seis:h-auto  seis:w-[35rem] bg-[var(--white)] p-8 border-2 border-black seis:rounded-[25px] flex flex-col uppercase gap-8'>
                        <div className='flex justify-between'>
                            <p className='font-garetBold seis:text-[1.2rem] text-[1rem] uppercase'>ADD CHARGER</p>
                            <p onClick={handleClose}
                                className='font-garetBold text-[1.5rem] seis:text-[2rem] cursor-pointer'>X</p>
                        </div>
                        <div className='w-full h-full flex flex-col gap-5'>
                            <p className='normal-case'>Escolha um tipo:</p>
                            <div className='w-full flex seis:flex-row flex-col gap-4 seis:gap-[3rem]'>
                                <button onDoubleClick={handleClose} onClick={() => handleAddCharger("30 KWa/H")} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center transition-color duration-500 ease-in-out items-center ${type === "30 KWa/H" ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                                    <p className='font-garetBold'>30 KWa/H</p>
                                    <p className='font-garetBold'>Até 1 carro</p>
                                </button>
                                <button onDoubleClick={handleClose} onClick={() => handleAddCharger("50 KWa/H")} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center transition-color duration-500 ease-in-out items-center ${type === "50 KWa/H" ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                                    <p className='font-garetBold'>50 KWa/H</p>
                                    <p className='font-garetBold'>Até 2 carros</p>
                                </button>
                                <button onDoubleClick={handleClose} onClick={() => handleAddCharger("150 KWa/H")} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center transition-color duration-500 ease-in-out items-center ${type === "150 KWa/H" ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                                    <p className='font-garetBold'>150 KWa/H</p>
                                    <p className='font-garetBold'>Até 4 carros</p>
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[2rem]'>
                            <div className='w-full flex justify-center'>
                                <p className='uppercase font-garetBold text-center seis:w-1/2 text-balance'>Receba recompensas por ter um <span className='text-[var(--primary)] uppercase font-garetBold'>supercharger</span> em casa</p>
                            </div>
                            <div className='seis:flex gap-10 hidden'>
                                <div className="flex flex-col gap-4 items-center justify-between">
                                    <img src={Service1} alt="icon" className='w-16' />
                                    <p className='uppercase font-garetBold text-[.7rem] text-center'>Coloque o seu carregador</p>
                                </div>
                                <div className="flex flex-col gap-4 items-center justify-between">
                                    <img src={Price} alt="icon" className='w-16' />
                                    <p className='uppercase font-garetBold text-[.7rem] text-center'>Reserve garagem com carregador elétrico</p>
                                </div>
                                <div className="flex flex-col gap-4 items-center justify-between">
                                    <img src={ChargerIcon} alt="icon" className='w-16' />
                                    <p className='uppercase font-garetBold text-[.7rem] text-center'>Seja um posto de carregamento</p>
                                </div>
                                <div className="flex flex-col gap-4 items-center justify-between">
                                    <img src={Service2} alt="icon" className='w-20' />
                                    <p className='uppercase font-garetBold text-[.7rem] text-center'>Receba recompensas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Transition>

    );
}
