import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";

//Responsive options for the carousel
function responsiveCarrousel(type: String) {
    const responsiveOptions = {
        "garageImages": {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        },
        "garageImagesPreview": {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 550 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 550, min: 0 },
                items: 1,
            },
        },
        "chargersGarage": {
            desktop: {
                breakpoint: { max: 3000, min: 1400 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1400, min: 1280 },
                items: 2,
            },
            tablet_sm: {
                breakpoint: { max: 1400, min: 1280 },
                items: 2,
            },
            tablet_xs: {
                breakpoint: { max: 1280, min: 700 },
                items: 3,
            },
            mobile_lg: {
                breakpoint: { max: 700, min: 450 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 450, min: 0 },
                items: 1,
            },
        }
    }
    return responsiveOptions[type as keyof typeof responsiveOptions];
}

const CarouselGarage = ({ children, type, multiple}: { children: React.ReactNode, type: String, multiple: boolean }) => {
    
    return (
        <Carousel
            showDots={false}
            responsive={responsiveCarrousel(type)}
            infinite={true}
            arrows={multiple}
            draggable={multiple}
            className="py-2"
            slidesToSlide={1}
            customLeftArrow={<IoIosArrowDropleft color="black" size={32} className="hidden" />}
            customRightArrow={<IoIosArrowDropright color="black" size={32} className="absolute right-0 cursor-pointer text-primary-400" />}
        >
            {children}
        </Carousel>
    )
}

export default CarouselGarage;