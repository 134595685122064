import React, { createContext, useState, useContext } from 'react';

const GarageContext = createContext({
  selectedGarage: "",
  setSelectedGarage: (value: string) => {},
});

export const GarageProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedGarage, setSelectedGarage] = useState("");

  return (
    <GarageContext.Provider value={{ selectedGarage, setSelectedGarage }}>
      {children}
    </GarageContext.Provider>
  );
};

export const useGarage = () => {
  const context = useContext(GarageContext);
  if (context === undefined) {
    throw new Error('useGarage must be used within a GarageProvider');
  }
  return context;
};